import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getDataFromAPI } from '../../../utils/query';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';

const UploadProgressChartModal = ({store, hideModal}) => {
    const [publishedProducts, setPublishedProducts] = useState([]);
    const [groupByType, setGroupByType] = useState('date');

    useEffect(() => {
        fetchPublishedProducts();
    }, []);

    const fetchPublishedProducts = async () => {
        const resPublishedProducts = await getDataFromAPI('products/?boomPublished=true&store=' + store + '&addtionalFeilds=ideaData');
        const publishedProducts = resPublishedProducts.data;

        const resUploadedProducts = await getDataFromAPI('products/?boomUploaded=true&store=' + store + '&addtionalFeilds=ideaData');
        const uploadedProducts = resUploadedProducts.data;

        let groupedProducts;
        if (groupByType === 'date') {
            const minDatePublished = new Date(Math.min(...publishedProducts.map(product => new Date(product.boomPublishTime))));
            const minDateUploaded = new Date(Math.min(...uploadedProducts.map(product => new Date(product.boomUploadTime))));
            const minDate = new Date(Math.min(minDatePublished, minDateUploaded));

            const today = new Date();
            const dateRange = [];

            for (let d = new Date(minDate); d <= today; d.setDate(d.getDate() + 1)) {
                dateRange.push(new Date(d).toLocaleDateString());
            }

            groupedProducts = dateRange.map(date => {
                const nbUploadedProducts = uploadedProducts.filter(product => new Date(product.boomUploadTime).toLocaleDateString() === date).length;
                return {
                    time: date,
                    nbPublishedProducts: publishedProducts.filter(product => new Date(product.boomPublishTime).toLocaleDateString() === date).length,
                    nbUploadedProducts: nbUploadedProducts > 100 ? 0 : nbUploadedProducts,
                };
            });

            console.log(groupedProducts);
        } else {
            // groupedProducts = res.data.reduce((arr, product) => {
            //     const date = new Date(product.boomPublishTime);
            //     date.setHours(date.getHours() + 7);
            //     const formattedTime = `${date.getHours()}h ${date.getDate()}/${date.getMonth() + 1}`;
            //     const itemIndex = arr.findIndex(item => item['time'] === formattedTime);
            //     if (itemIndex > -1) {
            //         arr[itemIndex].nbProducts += 1;
            //         if (product.ideaData) {
            //             arr[itemIndex].customIDs.push(product.ideaData.customID);
            //         }
            //     } else {
            //         arr.push({ time: formattedTime, nbProducts: 1, customIDs: product.ideaData ? [product.ideaData.customID] : [] });
            //     }
            //     return arr;
            // }, []);
        }

        console.log(groupedProducts);

        setPublishedProducts(groupedProducts);
    }

    return (
        <Modal size="xl" show={true} onHide={hideModal} className="upload-progress-chart-modal">
            <Modal.Header closeButton>
                <Modal.Title>Upload/Publish Progress</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {publishedProducts.length > 0 && (
                    <BarChart data={publishedProducts} width={1000} height={500} margin={{ top: 20, right: 30, left: 20, bottom: 10, }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="time" />
                        <YAxis yAxisId="left" />
                        <Tooltip />
                        <Bar type="monotone" dataKey="nbPublishedProducts" yAxisId="left" fill="green" stackId="a" />
                        <Bar type="monotone" dataKey="nbUploadedProducts" yAxisId="left" fill="red" stackId="a" />
                    </BarChart>
                )}
            </Modal.Body>
        </Modal>
    );
}

export default UploadProgressChartModal;