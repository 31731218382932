import { useEffect, useState } from 'react';
import Constant from '../../../Constant';
import './styles.scss';

import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faRobot, faTable, faStar } from '@fortawesome/free-solid-svg-icons';

import IdeaModal from '../../../pages/idea-list/idea-modal';
import { useSelector } from 'react-redux';
import Notification from '../../../utils/notification';
import { Badge } from 'react-bootstrap';
import BundleModal from '../bundle-modal';
import { hasPermission } from '../../../utils/auth';
import KeywordInfoModal from '../../product-map/keyword-info-modal';
import { getDataFromAPI } from '../../../utils/query';

const IdeasListTable = ({
    editingIdea = {},
    setEditingIdea = () => {},
    showIdeaModal = () => {},
    ideas,
    fetchIdeas,
    setIdeas,
    selectedIdeaIDs,
    setSelectedIdeaIDs,
    setLoading,
    filteringActivity,
    totalIdeas,
}) => {
    const selectedStoreID = useSelector((state) => state.filters.selectedStoreID);
    const selectedCategory = useSelector((state) => state.filters.selectedCategory);
    const searchKeyword = useSelector((state) => state.filters.searchKeyword);
    const selectedGrades = useSelector((state) => state.filters.selectedGrades);
    const selectedSubjects = useSelector((state) => state.filters.selectedSubjects);
    const selectedTaskStep = useSelector((state) => state.filters.selectedTaskStep);
    const activities = useSelector(state => state.filters.activities);
    const { routePermissions, activityPermissions } = useSelector((state) => state.permissions);

    const [userRoles, setUserRoles] = useState([]);
    const [notification, setNotification] = useState(null);
    const [duplicatingIdea, setDuplicatingIdea] = useState(null);
    const [bundleInModal, setBundleInModal] = useState(null);
    const [keywordInModal, setKeywordInModal] = useState(null);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    const getFilteredIdeas = () => {
        const filteredProducts = ideas.filter(p => {
            if (selectedStoreID !== null && !isNaN(selectedStoreID) && p.stores.indexOf(selectedStoreID) === -1) {
                return false;
            }
            if (selectedTaskStep !== null && p.task?.step !== selectedTaskStep) {
                return false;
            }
            if (searchKeyword) {
                let found = false;
                const keywords = searchKeyword.toLowerCase().trim().split(' ');
                for (let i = 0; i < keywords.length; i++) {
                    if (p.name.toLowerCase().indexOf(keywords[i]) > -1 ||
                        (p.title && p.title.toLowerCase().indexOf(keywords[i]) > -1) ||
                        p.customID.toLowerCase().indexOf(keywords[i]) > -1) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    return false;
                }
            }
            if (selectedCategory && p.category !== selectedCategory) {
                return false;
            }
            if (selectedGrades.length > 0) {
                if (p.grades === null) {
                    return false;
                } else if (!p.grades.split(',').some(item => selectedGrades.includes(item))) {
                    return false;
                }
            }
            if (selectedSubjects.length > 0) {
                if (p.subjects === null) {
                    return false;
                } else if (!p.subjects.split(',').some(item => selectedSubjects.includes(item))) {
                    return false;
                }
            }

            if (filteringActivity !== 'all') {
                const activity = activities.find(act => act.id === filteringActivity);
                if (activity && p.tags.indexOf(activity['tag']) === -1) {
                    return false;
                }
            }

            return true;
        });
        return filteredProducts;
    }

    const showKeywordInModal = async(keywordID) => {
        setLoading(true);
        const res = await getDataFromAPI('resources/keywords/' + keywordID + '/');
        setKeywordInModal(res.data);
        setLoading(false);
    }

    return (
        <div className="idea-list-table">
            <div className="table-prefix">
                <div className="table-right">
                    <div className="table-nb-lines">
                        {getFilteredIdeas().length}/{totalIdeas} results
                    </div>
                </div>
            </div>
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>
                            <Form.Check type="checkbox" id={'checkbox-all-product'}
                                checked={selectedIdeaIDs.length === getFilteredIdeas().length}
                                onChange={() => {
                                    const uploadedProducts = getFilteredIdeas();
                                    if (selectedIdeaIDs.length === uploadedProducts.length) {
                                        setSelectedIdeaIDs([]);
                                    } else {
                                        setSelectedIdeaIDs(uploadedProducts.map(p => p.id));
                                    }
                                }} />
                        </th>
                        <th>Code</th>
                        <th>Thumbnail</th>
                        <th>Title</th>
                        <th>Subjects</th>
                        <th>Grades</th>
                        <th>Keyword</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {getFilteredIdeas().map((idea, index) => {
                        return (
                            <tr key={idea.id}>
                                <td className="index-cell">
                                    {index + 1}
                                    <Form.Check type="checkbox" id={'checkbox-product-' + idea.id}
                                        checked={selectedIdeaIDs.indexOf(idea.id) > -1}
                                        onChange={() => {
                                            if (selectedIdeaIDs.indexOf(idea.id) > -1) {
                                                setSelectedIdeaIDs(selectedIdeaIDs.filter(cID => cID !== idea.id));
                                            } else {
                                                setSelectedIdeaIDs(selectedIdeaIDs.concat([idea.id]));
                                            }
                                        }} />
                                </td>
                                <td>
                                    <div className="idea-customID" onClick={() => showIdeaModal(idea)}>
                                        <strong>{idea.customID}</strong>
                                        {idea.star && (
                                            <FontAwesomeIcon icon={faStar} className="text-warning" />
                                        )}
                                    </div>
                                </td>
                                <td className="product-thumbnail-cell">
                                    {idea.thumbnail && (
                                        <img src={idea.thumbnail} className="product-thumbnail" />
                                    )}
                                    {(idea.thumbnailTitle === null || idea.thumbnailSubTitle === null || (idea.storeData.marketplace === 'tpt' && !(idea.thumbnailSubTitle2 !== null || idea.thumbnailSubTitle3 !== null))) && (
                                        <Badge bg="danger" className="mt-3">Missing Thumbnail Text</Badge>
                                    )}
                                </td>
                                <td className="product-name-cell">
                                    <div className="product-title" onClick={() => showIdeaModal(idea)}>{idea.title}</div>
                                    <div className="other-tools">
                                        {hasPermission(userRoles, activityPermissions['DUPLICATE_PRODUCT_IDEA']) && (
                                            <span title="duplicate" onClick={() => setDuplicatingIdea(idea)} className="tool-icon">
                                                <FontAwesomeIcon icon={faCopy} />
                                            </span>
                                        )}
                                        {hasPermission(userRoles, routePermissions['/content-studio']) && idea.category === Constant.WORKSHEET_CATEGORY_ID && (
                                            <a title="ai studio" href={'/outline-idea?customID=' + idea.customID} target="_blank" className="tool-icon">
                                                <FontAwesomeIcon icon={faTable} />
                                            </a>
                                        )}
                                        {hasPermission(userRoles, routePermissions['/content-studio']) && (
                                            <a title="ai studio" href={'/content-studio?customID=' + idea.customID} target="_blank" className="tool-icon">
                                                <FontAwesomeIcon icon={faRobot} />
                                            </a>
                                        )}
                                    </div>
                                </td>
                                <td>{idea.subjects}</td>
                                <td>
                                    {idea.grades && (
                                        <div className="grades-cell">
                                            {idea.grades.replace(/,/g, ", ")}
                                        </div>
                                    )}
                                </td>
                                <td>
                                    <div className="keyword-name" onClick={() => showKeywordInModal(idea.mainKeyword)}>
                                        {idea.mainKeyword}. {idea.mainKeywordName}
                                    </div>
                                </td>
                                <td>
                                    {idea.tasks.length > 0 && (
                                        <div>{idea.tasks[0].stepLabel}</div>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            {editingIdea !== null && (
                <IdeaModal
                    idea={editingIdea}
                    closeModal={(action, data) => {
                        if (action === 'update') {
                            setNotification('Update successfully');
                            setIdeas(prev => prev.map(idea => {
                                if (idea.id === editingIdea.id) {
                                    return {...idea, ...data};
                                }
                                return idea;
                            }));
                        } else if (action === 'moveIdeaFromStoreToStore') {
                            setIdeas(prev => prev.filter(id => id.customID !== editingIdea.customID));
                        } else if (action === 'delete') {
                            setNotification('Delete successfully');
                            fetchIdeas();
                        }

                        setEditingIdea(null);
                    }}
                />
            )}
            {notification && (
                <Notification title={notification} closeNotification={() => setNotification(null)} />
            )}
            {duplicatingIdea !== null && (
                <IdeaModal
                    idea={duplicatingIdea}
                    duplicating={true}
                    closeModal={() => {
                        setNotification('Duplicate successfully');
                        setDuplicatingIdea(null);
                        fetchIdeas();
                    }}
                />
            )}
            {bundleInModal !== null && (
                <BundleModal
                    bundle={bundleInModal}
                    allProducts={ideas.filter(p => p.store === selectedStoreID)}
                    closeModal={action => {
                        if (action === 'update') {
                            fetchIdeas();
                        }
                        setBundleInModal(null);
                    }} />
            )}

            {keywordInModal !== null && (
                <KeywordInfoModal
                    keywordID={keywordInModal.id}
                    setLoading={setLoading}
                    hideModal={() => setKeywordInModal(null)}
                />
            )}
        </div>
    );
}

export default IdeasListTable;