import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './styles.scss';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Constant from '../../Constant';
import { Dropdown, NavDropdown } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import { hasPermission, logout } from '../../utils/auth';
import { useSelector } from 'react-redux';
import HotKeyTutorialModal from './hotkey-tutorial-modal';

const Header = () => {
    const { routePermissions, activityPermissions } = useSelector((state) => state.permissions);

    const [userRoles, setUserRoles] = useState([]);
    const location = useLocation();
    const [showHotKeyTutorialModal, setShowHotKeyTutorialModal] = useState(false);

    const [systemTicket, setSystemTicket] = useState();

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
        fetchResetSystemTicket();
    }, []);

    const fetchResetSystemTicket = async() => {
        const res = await getDataFromAPI('product-dev/system-tickets/1/');
        setSystemTicket(res.data);
    }

    const backToAdmin = () => {
        localStorage.setItem(Constant.LOCAL_STORAGE_TOKEN, localStorage.getItem(Constant.LOCAL_STORAGE_ADMIN_TOKEN));
        localStorage.setItem(Constant.LOCAL_STORAGE_ROLE, 'admin');
        localStorage.setItem(Constant.LOCAL_STORAGE_USER_EMAIL, localStorage.getItem(Constant.LOCAL_STORAGE_ADMIN_EMAIL));
        localStorage.setItem(Constant.LOCAL_STORAGE_STAFF_ID, localStorage.getItem(Constant.LOCAL_STORAGE_ADMIN_ID));
        window.location.reload();
    }

    const resetSystem = async(type) => {
        if (type === 'ai') {
            const res = await updateDataAPI('PATCH', 'product-dev/system-tickets/1/', {
                restartAI: !systemTicket['restartAI'],
            });
            setSystemTicket(res.data);
        } else {
            const res = await updateDataAPI('PATCH', 'product-dev/system-tickets/1/', {
                restartDesign: !systemTicket['restartDesign'],
            });
            setSystemTicket(res.data);
        }
    }

    return (
        <Navbar bg="light" className="header">
            <Container fluid>
                <Navbar.Brand href="/">
                    <img className="logo" src="/logo.png" alt="logo" />
                </Navbar.Brand>
                <Nav className="me-auto">
                    <Nav.Link href="/task-management" className={location.pathname === '/task-management' && 'active'}>
                        Tasks
                    </Nav.Link>

                    {hasPermission(userRoles, routePermissions['/ideas']) && (
                        <Nav.Link href="/ideas" className={location.pathname === '/ideas' && 'active'}>
                            Ideas
                        </Nav.Link>
                    )}

                    <NavDropdown title="Templates" id="nav-templates" className={['/templates-management', '/store-templates-config', '/activities', '/store-groups', '/store-groups-data', '/themes'].indexOf(location.pathname) > -1 && 'active'}>
                        {hasPermission(userRoles, routePermissions['/templates-management']) && (
                            <NavDropdown.Item href="/templates-management" className={location.pathname === '/templates-management' && 'active'}>Templates</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/store-templates-config']) && (
                            <NavDropdown.Item href="/store-templates-config" className={location.pathname === '/store-templates-config' && 'active'}>Store Templates</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/activity-template-sets']) && (
                            <NavDropdown.Item href="/activity-template-sets" className={location.pathname === '/activity-template-sets' && 'active'}>Template Sets</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/activities']) && (
                            <NavDropdown.Item href="/activities" className={location.pathname === '/activities' && 'active'}>Activities</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/store-groups']) && (
                            <NavDropdown.Item href="/store-groups" className={location.pathname === '/store-groups' && 'active'}>Store Groups</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/store-groups-data']) && (
                            <NavDropdown.Item href="/store-groups-data" className={location.pathname === '/store-groups-data' && 'active'}>Store Groups Data</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/themes']) && (
                            <NavDropdown.Item href="/themes" className={location.pathname === '/themes' && 'active'}>Themes</NavDropdown.Item>
                        )}
                    </NavDropdown>

                    <NavDropdown title="Data" id="nav-product-map" className={['/product-map', '/data-sets', '/events', '/topics'].indexOf(location.pathname) > -1 && 'active'}>
                        {hasPermission(userRoles, routePermissions['/product-map']) && (
                            <NavDropdown.Item href="/product-map" className={location.pathname === '/product-map' && 'active'}>Product Map</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/store-map']) && (
                            <NavDropdown.Item href="/store-map" className={location.pathname === '/store-map' && 'active'}>Store Map</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/data-sets']) && (
                            <NavDropdown.Item href="/data-sets" className={location.pathname === '/data-sets' && 'active'}>Data Sets</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/events']) && (
                            <NavDropdown.Item href="/events" className={location.pathname === '/events' && 'active'}>Events</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/topics']) && (
                            <NavDropdown.Item href="/topics" className={location.pathname === '/topics' && 'active'}>Topics</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/training-texts']) && (
                            <NavDropdown.Item href="/training-texts" className={location.pathname === '/training-texts' && 'active'}>Training Texts</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/apply-image-to-dataset']) && (
                            <NavDropdown.Item href="/apply-image-to-dataset" className={location.pathname === '/apply-image-to-dataset' && 'active'}>Apply Images To DataSet</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/tpt-crawl-requests']) && (
                            <NavDropdown.Item href="/tpt-crawl-requests" className={location.pathname === '/tpt-crawl-requests' && 'active'}>TPT Requests</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/tpt-crawler']) && (
                            <NavDropdown.Item href="/tpt-crawler" className={location.pathname === '/tpt-crawler' && 'active'}>TPT Crawler</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/outlines']) && (
                            <NavDropdown.Item href="/outlines" className={location.pathname === '/outlines' && 'active'}>
                                Outlines
                            </NavDropdown.Item>
                        )}
                    </NavDropdown>

                    <NavDropdown title="Tickets" id="nav-tickets" className={['/design-tickets', '/user-feedbacks'].indexOf(location.pathname) > -1 && 'active'}>
                        {hasPermission(userRoles, routePermissions['/design-tickets']) && (
                            <NavDropdown.Item href="/design-tickets" className={location.pathname === '/design-tickets' && 'active'}>Design Tickets</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/user-feedbacks']) && (
                            <NavDropdown.Item href="/user-feedbacks" className={location.pathname === '/user-feedbacks' && 'active'}>User Feedback Tickets</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/upload-tickets']) && (
                            <NavDropdown.Item href="/upload-tickets" className={location.pathname === '/upload-tickets' && 'active'}>Upload Tickets</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/upload-boom-products']) && (
                            <NavDropdown.Item href="/upload-boom-products" className={location.pathname === '/upload-boom-products' && 'active'}>Upload Boom</NavDropdown.Item>
                        )}
                    </NavDropdown>


                    {hasPermission(userRoles, routePermissions['/collected-resources']) && (
                        <NavDropdown title="Collected Resources" id="nav-collected-resources" className={['/design-tickets', '/user-feedbacks'].indexOf(location.pathname) > -1 && 'active'}>
                            <NavDropdown.Item href="/collected-resources" className={location.pathname === '/collected-resources' && 'active'}>Collected Resources</NavDropdown.Item>
                            {hasPermission(userRoles, routePermissions['/collected-resources-search-queries']) && (
                                <NavDropdown.Item href="/collected-resources-search-queries" className={location.pathname === '/collected-resources-search-queries' && 'active'}>Search Queries</NavDropdown.Item>
                            )}
                        </NavDropdown>
                    )}


                    {/* <NavDropdown title="Dashboard" id="nav-dashboards" className={['/bot-status-dashboard', '/staff-gen-data-tasks-dashboard'].indexOf(location.pathname) > -1 && 'active'}>
                        {hasPermission(userRoles, routePermissions['/bot-status-dashboard']) && (
                            <NavDropdown.Item href="/bot-status-dashboard" className={location.pathname === '/bot-status-dashboard' && 'active'}>Bot Dashboard</NavDropdown.Item>
                        )}
                        {hasPermission(userRoles, routePermissions['/staff-gen-data-tasks-dashboard']) && (
                            <NavDropdown.Item href="/staff-gen-data-tasks-dashboard" className={location.pathname === '/staff-gen-data-tasks-dashboard' && 'active'}>Staff Tasks Dashboard</NavDropdown.Item>
                        )}
                    </NavDropdown> */}

                    {/* {hasPermission(userRoles, routePermissions['/multi-languages-ideas']) && (
                        <Nav.Link href="/multi-languages-ideas" className={location.pathname === '/multi-languages-ideas' && 'active'}>
                            Multi Langs
                        </Nav.Link>
                    )} */}
                </Nav>
                {/* {userRoles.includes('admin') && (
                    <StaffPerformanceBar />
                )} */}
                {userRoles && (
                    <Navbar.Collapse className="justify-content-end">
                        {/* {localStorage.getItem(Constant.LOCAL_STORAGE_ADMIN_TOKEN) && (
                            <Form className="pick-a-staff-form">
                                <select className="form-select" onChange={e => pickAStaff(e.target.value)}>
                                    <option>Select Staff View</option>
                                    {staffs.map((staff, index) => (
                                        <option key={staff.id} value={staff.id}>{staff.name} ({staff.roles.join(',')})</option>
                                    ))}
                                </select>
                                <Button size="sm" variant="link" onClick={backToAdmin}>Cancel</Button>
                            </Form>
                        )} */}
                        <Navbar.Text>
                            <Dropdown>
                                <Dropdown.Toggle size="sm" id="user-dropdown-header">
                                    {localStorage.getItem(Constant.LOCAL_STORAGE_USER_NAME) && (
                                        <span className="username">{localStorage.getItem(Constant.LOCAL_STORAGE_USER_NAME)}</span>
                                    )}
                                    {localStorage.getItem(Constant.LOCAL_STORAGE_ROLES) && (
                                        <span className="user-roles"><br />{JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)).join(', ')}</span>
                                    )}
                                </Dropdown.Toggle>

                                <Dropdown.Menu align="end">
                                    {hasPermission(userRoles, activityPermissions['RESET_AI']) && systemTicket && (
                                        <>
                                            <Dropdown.Item className={systemTicket['restartAI'] ? 'to-reset' : ''} onClick={() => resetSystem('ai')}>Reset AI</Dropdown.Item>
                                            <Dropdown.Divider />
                                        </>
                                    )}
                                    {hasPermission(userRoles, activityPermissions['RESET_DESIGN']) && systemTicket && (
                                        <>
                                            <Dropdown.Item className={systemTicket['restartDesign'] ? 'to-reset' : ''} onClick={() => resetSystem('design')}>Reset Design</Dropdown.Item>
                                            <Dropdown.Divider />
                                        </>
                                    )}
                                    <Dropdown.Item href="/performance-ticket">New Performance Ticket</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={() => setShowHotKeyTutorialModal(true)}>HotKey Tutorial</Dropdown.Item>
                                    <Dropdown.Divider />
                                    {userRoles.includes('admin') && (
                                        <Dropdown.Item onClick={() => {
                                            window.location.href = '/copy-to-dev';
                                        }}>Copy To Dev</Dropdown.Item>
                                    )}
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Navbar.Text>
                    </Navbar.Collapse>
                )}
            </Container>

            {showHotKeyTutorialModal && (
                <HotKeyTutorialModal hideModal={() => setShowHotKeyTutorialModal(false)} />
            )}
        </Navbar>
    );
}

export default Header;