import { useEffect, useState } from 'react';
import { getDataFromAPI } from '../../../utils/query';
import { Button, Table } from 'react-bootstrap';

import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faUpLong } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

const IdeaTicketsStats = ({setLoading}) => {
    const allThemes = useSelector(state => state.filters.themes);
    const [statsByStore, setStatsByStore] = useState(null);

    useEffect(() => {
        fetchStats();
    }, []);

    const fetchStats = async() => {
        setLoading(true);
        const res = await getDataFromAPI('resources/get-idea-tickets-stats-by-store/');
        setStatsByStore(res.data);
        setLoading(false);
    }

    const getPercentage = (data) => {
        return parseInt(data['nbUploadedIdeas'] / (data['nbIdeas'] + data['nbRemainingTickets']) * 100);
    }

    if (statsByStore === null) {
        return;
    }

    return (
        <div className="idea-tickets-stats">
            <Button size="sm" onClick={() => {
                fetchStats();
            }}>Refresh</Button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Store</th>
                        {Object.keys(statsByStore[Object.keys(statsByStore)[0]]).map(theme => (
                            <th key={theme.id} className={allThemes.find(t => t.slug === theme).prior ? 'text-success' : ''}>{theme}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(statsByStore).filter(storeID => Object.values(statsByStore[storeID]).filter(data => data['nbIdeas'] > 0).length > 0).map(storeID => (
                        <tr key={storeID}>
                            <td>
                                <a href={'/ideas?selectedStoreID=' + storeID} target="_blank">
                                    {storeID}
                                </a>
                            </td>
                            {Object.values(statsByStore[storeID]).map(data => (
                                <>
                                    <td className="stat-cell">
                                        {data['nbIdeas'] > 0 && (
                                            <div>
                                                <div>
                                                    {data['nbUploadedIdeas']} / ({data['nbIdeas']} + {data['nbRemainingTickets']})
                                                </div>
                                                <div>
                                                    <div className={getPercentage(data) > 80 ? 'text-success' : (getPercentage(data) > 50 ? 'text-warning' : 'text-danger')}>({getPercentage(data)}%)</div>
                                                    <div className="ticket-progress">
                                                        {data['nbUploadedIdeasYesterday'] > 0 && (
                                                            <span className="text-success">
                                                                <FontAwesomeIcon icon={faUpLong} />
                                                                {data['nbUploadedIdeasYesterday']}
                                                            </span>
                                                        )}
                                                        {data['nbPublishedIdeasYesterday'] > 0 && (
                                                            <span className="text-success">
                                                                <FontAwesomeIcon icon={faCheckCircle} />
                                                                {data['nbPublishedIdeasYesterday']}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </td>
                                </>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default IdeaTicketsStats;