import { Modal } from 'react-bootstrap';
import DataSetRowImagesPreview from '../dataset-row-images-preview';

import './styles.scss';

const DataSetRowsControlModal = ({ rows, hideModal }) => {
    return (
        <Modal fullscreen={true} show={true} onHide={hideModal}
            className="dataset-rows-control-modal"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="rows">
                    {rows.map(row => (
                        <div key={row.id} onClick={() => hideModal('focus', row.rowIndex)}>
                            <DataSetRowImagesPreview
                                row={row}
                                setShowDataSetImagesEditorModal={() => {}}
                                setActiveImageIndexInModal={() => {}}
                            />
                            <div className="text-center">Row {row.rowIndex + 1}</div>
                        </div>
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default DataSetRowsControlModal;