import { useSelector } from 'react-redux';
import Select from 'react-select';

import './styles.scss';

const FilterByActivity = ({filteringActivity, setFilteringActivity, storeGroup = null, disabled = false}) => {
    const activities = useSelector(state => state.filters.activities);

    const options = [
        { value: 'all', label: 'All Activities' },
        ...activities.filter(activity => {
            if (storeGroup && storeGroup['activities'].indexOf(activity.slug) === -1) {
                return false;
            }

            return true;
        }).map(activity => ({
            value: activity.id,
            label: activity.label
        }))
    ];

    const selectedOption = options.find(option => option.value === filteringActivity) || options[0];

    return (
        <div className="filter-by-activity">
            <Select
                value={selectedOption}
                onChange={option => setFilteringActivity(option.value)}
                options={options}
                className="react-select-container"
                classNamePrefix="react-select"
                isDisabled={disabled}
            />
        </div>
    );
}

export default FilterByActivity;