import { useEffect, useState } from 'react';
import { Button, Container, Form, Spinner, Tab, Table, Tabs } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import Constant from '../../Constant';

import './styles.scss';
import FilterByStore from '../../filters/store/index_v1';
import FilterByTheme from '../../filters/theme';
import StarIdeasTab from './star-ideas-tab';

const UploadBoomProductsPage = () => {
    const [toPublishBoomProducts, setToPublishBoomProducts] = useState([]);
    const [toReviewBoomProducts, setToReviewBoomProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filteringStore, setFilteringStore] = useState('all');
    const [filteringTheme, setFilteringTheme] = useState('all');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [activeTab, setActiveTab] = useState('starIdeas');

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async() => {
        setLoading(true);
        const resToPublish = await getDataFromAPI('products/?toPublishBoom=true&additionalFields=ideaData');
        setToPublishBoomProducts(resToPublish.data);

        const resToReview = await getDataFromAPI('products/?toReviewBoom=true&additionalFields=ideaData');
        setToReviewBoomProducts(resToReview.data);
        setLoading(false);
    }

    const clearErrorToReValidate = async(productID) => {
        setLoading(true);
        await updateDataAPI('PATCH', 'products/' + productID + '/', {
            boomValidateErrors: null
        });
        setToReviewBoomProducts(toReviewBoomProducts.filter(p => p['id'] !== productID));
        setLoading(false);
    }

    const setProductUploaded = async(productIDs) => {
        setLoading(true);
        for (let i = 0; i < productIDs.length; i++) {
            await updateDataAPI('PATCH', 'products/' + productIDs[i] + '/', {
                boomUploaded: true,
                boomUploadTime: new Date(),
            });
        }
        if (activeTab === 'toPublish') {
            setToPublishBoomProducts(toPublishBoomProducts.filter(p => productIDs.indexOf(p['id']) === -1));
        } else {
            setToReviewBoomProducts(toReviewBoomProducts.filter(p => productIDs.indexOf(p['id']) === -1));
        }
        if (selectedProducts.length > 0) {
            setSelectedProducts(selectedProducts.filter(id => productIDs.indexOf(id) === -1));
        }
        setLoading(false);
    }

    const reloadProduct = async(productID) => {
        setLoading(true);
        await updateDataAPI('POST', 'resources/reupload-boom-product/?productID=' + productID);
        setToReviewBoomProducts(toReviewBoomProducts.filter(p => p['id'] !== productID));
        setLoading(false);
    }

    const selectThenOpenBoomLink = (product) => {
        window.open(product['url'].replace('/deck/', '/editDeck/'), '_blank', 'noopener,noreferrer');
        if (selectedProducts.indexOf(product['id']) === -1) {
            setSelectedProducts([...selectedProducts, product['id']]);
        }
    }

    const clearAllErrors = async() => {
        const toReviewProductIDs = toReviewBoomProducts.filter(product => {
            if (filteringStore !== 'all' && product.store != filteringStore) {
                return false;
            }

            return true;
        }).map(product => product['id']);

        setLoading(true);
        for (let i = 0; i < toReviewProductIDs.length; i++) {
            await updateDataAPI('PATCH', 'products/' + toReviewProductIDs[i] + '/', {
                boomValidateErrors: null
            });
        }
        setToReviewBoomProducts(toReviewBoomProducts.filter(p => toReviewProductIDs.indexOf(p['id']) === -1));
        setLoading(false);
    }

    return (
        <Container className="upload-boom-products-page" fluid>
            <div className="page-header">
                <div>
                    <FilterByStore filteringStore={filteringStore} setFilteringStore={setFilteringStore} marketplace="boom" />
                    <FilterByTheme filteringTheme={filteringTheme} setFilteringTheme={setFilteringTheme} />
                </div>
                <div>
                    {selectedProducts.length > 0 && (
                        <Button onClick={() => setProductUploaded(selectedProducts)}>Set Uploaded</Button>
                    )}
                    <Button onClick={fetchProducts}>Refresh</Button>
                    {activeTab === 'toReview' && (
                        <Button onClick={clearAllErrors}>Clear all errors</Button>
                    )}
                </div>
            </div>
            <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} id="upload-boom-tabs" className="mt-5">
                <Tab eventKey="starIdeas" title="Star Ideas">
                    {filteringStore !== 'all' && (
                        <StarIdeasTab
                            setLoading={setLoading}
                            filteringStore={filteringStore}
                            filteringTheme={filteringTheme}
                        />
                    )}
                </Tab>
                <Tab eventKey="toPublish" title={'To Publish (' + toPublishBoomProducts.filter(product => {
                        if (filteringStore !== 'all' && product.store != filteringStore) {
                            return false;
                        }

                        if (filteringTheme !== 'all' && product.ideaData.tags.indexOf(filteringTheme) === -1) {
                            return false;
                        }

                        return true;
                    }).length + ')'}>
                    <Table striped bordered hover>
                        <tbody>
                            {toPublishBoomProducts.filter(product => {
                                if (filteringStore !== 'all' && product.store != filteringStore) {
                                    return false;
                                }

                                if (filteringTheme !== 'all' && product.ideaData.tags.indexOf(filteringTheme) === -1) {
                                    return false;
                                }

                                return true;
                            }).map((product, index) => (
                                <tr key={product['id']}>
                                    <td>{index + 1}</td>
                                    <td>Store {product.store}</td>
                                    <td>
                                        <a href={Constant.API_URL + 'admin/free_resources/product/' + product['id']} target="_blank">
                                            {product['customID']}
                                        </a>
                                    </td>
                                    <td>
                                        <img src={product['ideaData']['thumbnail']} />
                                        <div>
                                            <a href={'/content-studio/?customID=' + product['customID']} target="_blank">
                                                {product['ideaData']['title']}
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="boom-link-cell">
                                            <Form.Check
                                                type="checkbox"
                                                onChange={() => {
                                                    if (selectedProducts.indexOf(product['id']) > -1) {
                                                        setSelectedProducts(selectedProducts.filter(id => id !== product['id']));
                                                    } else {
                                                        setSelectedProducts([...selectedProducts, product['id']]);
                                                    }
                                                }}
                                                checked={selectedProducts.indexOf(product['id']) > -1}
                                            />
                                            <span onClick={() => selectThenOpenBoomLink(product)}>
                                                boom link
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <Button onClick={() => setProductUploaded([product['id']])}>Uploaded</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Tab>
                <Tab eventKey="toReview" title={'To Review (' + toReviewBoomProducts.filter(product => {
                    if (filteringStore !== 'all' && product.store != filteringStore) {
                        return false;
                    }

                    if (filteringTheme !== 'all' && product.ideaData.tags.indexOf(filteringTheme) === -1) {
                        return false;
                    }

                    return true;
                }).length + ')'}>
                    <Table striped bordered hover>
                        <tbody>
                            {toReviewBoomProducts.filter(product => {
                                if (filteringStore !== 'all' && product.store != filteringStore) {
                                    return false;
                                }

                                if (filteringTheme !== 'all' && product.ideaData.tags.indexOf(filteringTheme) === -1) {
                                    return false;
                                }

                                return true;
                            }).map((product, index) => (
                                <tr key={product['id']}>
                                    <td>{index + 1}</td>
                                    <td>Store {product.store}</td>
                                    <td>
                                        <a href={Constant.API_URL + 'admin/free_resources/product/' + product['id']} target="_blank">
                                            {product['customID']}
                                        </a>
                                    </td>
                                    <td>
                                        <img src={product['ideaData']['thumbnail']} />
                                        <div>
                                            <a href={'/content-studio/?customID=' + product['customID']} target="_blank">
                                                {product['ideaData']['title']}
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <a href={product['url'].replace('/deck/', '/editDeck/')} target="_blank" rel="noreferrer">
                                            boom link
                                        </a>
                                    </td>
                                    <td className="errors-cell">
                                        <Form.Control as="textarea" value={product['boomValidateErrors']} rows={10} disabled />
                                    </td>
                                    <td>
                                        <div className="btns">
                                            <Button size="sm" onClick={() => clearErrorToReValidate(product['id'])}>Clear Error</Button>
                                            <Button size="sm" onClick={() => reloadProduct(product['id'])}>To Reupload</Button>
                                            <Button size="sm" onClick={() => setProductUploaded([product['id']])}>Uploaded</Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Tab>
            </Tabs>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    );
}

export default UploadBoomProductsPage;