import { useEffect, useState } from 'react';
import { Button, ButtonGroup, Dropdown, Table } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import DataSetModal from '../../../dataset/data-set-row/data-set-modal';
import FilterByTheme from '../../../../filters/theme';

import './styles.scss';

const DataSetsTable = ({keyword}) => {
    const [dataSets, setDataSets] = useState([]);
    const [showNewDataSetModal, setShowNewDataSetModal] = useState(false);
    const [filteringTheme, setFilteringTheme] = useState('all');

    useEffect(() => {
        fetchDataSets();
    }, []);

    const fetchDataSets = async() => {
        const res = await getDataFromAPI('resources/data-sets/?page_size=1000&keywordID=' + keyword.id);
        setDataSets(res.data['results']);
    }

    const createKahootDataSet = async() => {
        const res = await updateDataAPI('POST', 'resources/data-sets/', {
            dataType: 'JSON',
            activity: 'MCQ',
            dataSource: 'kahoot',
            keywords: [keyword.id],
            quality: 'medium',
            audioAvailable: 'no',
            store: keyword.boomStores[0],
        });
        setDataSets((prev) => ([...prev, res.data]));
    }

    return (
        <div className="keyword-data-sets-table">
            <div className="table-header">
                <div>
                    <FilterByTheme filteringTheme={filteringTheme} setFilteringTheme={setFilteringTheme} valueField="slug" />
                </div>
                <div>
                    <Button onClick={fetchDataSets}>Refresh</Button>
                    <Dropdown as={ButtonGroup}>
                        <Button onClick={() => setShowNewDataSetModal(true)}>New Data Set</Button>

                        <Dropdown.Toggle split id="create-ticket-dropdown" />

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={createKahootDataSet}>Create Kahoot DataSet</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Data Type</th>
                        <th>Activity</th>
                        <th>Source</th>
                        <th>Themes</th>
                        <th>Quality</th>
                        <th>Audio</th>
                        <th>Store</th>
                        <th>Validated</th>
                    </tr>
                </thead>
                <tbody>
                    {dataSets.filter(dataSet => {
                        if (filteringTheme !== 'all' && ((dataSet.seasons && dataSet.seasons.split(',').indexOf(filteringTheme) === -1) || dataSet.seasons === null)) {
                            return false;
                        }

                        return true;
                    }).map(dataSet => (
                        <tr key={dataSet.id} className="dataSet">
                            <td>
                                <a href={'/data-set/?id=' + dataSet.id} target="_blank">
                                    {dataSet.id}
                                </a>
                            </td>
                            <td>{dataSet.dataType}</td>
                            <td>{dataSet.activity}</td>
                            <td>
                                {dataSet.storyName ? (
                                    <span>{dataSet.dataSource} - {dataSet.storyName}</span>
                                ) : (
                                    <span>{dataSet.dataSource}</span>
                                )}
                            </td>
                            <td>{dataSet.seasons}</td>
                            <td>{dataSet.quality}</td>
                            <td>{dataSet.audioAvailable ?? ''}</td>
                            <td>{dataSet.store}</td>
                            <td>
                                {dataSet.validated && (
                                    <div>{dataSet.nbQuestionsValidated} rows</div>
                                )}
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={4}>
                            <div>
                                <strong>{dataSets.filter(dataSet => dataSet.dataType === 'JSON' && dataSet.validated).reduce((total, dataSet) => total + dataSet.totalRows, 0)} good questions</strong>
                            </div>
                            <div>
                                {dataSets.filter(dataSet => dataSet.dataType !== 'JSON' && dataSet.validated).reduce((total, dataSet) => total + dataSet.nbQuestionsValidated, 0)} medium questions
                            </div>
                        </td>
                        <td>
                            <Button onClick={fetchDataSets} variant="link" size="sm">Refresh</Button>
                        </td>
                    </tr>
                </tbody>
            </Table>

            {showNewDataSetModal && (
                <DataSetModal
                    hideModal={() => setShowNewDataSetModal(false)}
                    setDataSets={setDataSets}
                />
            )}
        </div>
    );
}

export default DataSetsTable;