import { useEffect, useState } from 'react';
import { Badge, Button, Container, Form, Spinner, Table } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';

import './styles.scss';
import Constant from '../../Constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import IdeaModal from '../idea-list/idea-modal';
import FilterByStore from '../../filters/store/index_v1';
import IdeaTicketsStats from '../../components/upload-tickets/idea-tickets-stats';

const UploadTicketsPage = () => {
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filteredActionType, setFilteredActionType] = useState('all');
    const [ideaInIdeaModal, setIdeaInIdeaModal] = useState(null);
    const [filteringStore, setFilteringStore] = useState('all');

    useEffect(() => {
        if (filteringStore !== 'all') {
            fetchPendingUploadTickets();
        }
    }, [filteringStore]);

    const fetchPendingUploadTickets = async () => {
        setLoading(true);

        const res = await getDataFromAPI('product-dev/upload-tickets/?done=false&store=' + filteringStore);

        setTickets(res.data);
        setLoading(false);
    }

    const deleteTicket = async(ticketID) => {
        setLoading(true);
        await updateDataAPI('DELETE', 'product-dev/upload-tickets/' + ticketID + '/');
        setTickets(prev => prev.filter(t => t.id !== ticketID));
        setLoading(false);
    }

    const clearErrors = async(ticketID = null) => {
        if (filteringStore === 'all') {
            return;
        }

        if (window.confirm('Are you sure?')) {
            setLoading(true);
            if (ticketID) {
                const res = await updateDataAPI('PATCH', 'product-dev/upload-tickets/' + ticketID + '/', {
                    hasError: false,
                    failedReason: null,
                });
                setTickets(prev => prev.map(t => {
                    if (t.id === res.data['id']) {
                        return res.data;
                    }
                    return t;
                }));
            } else {
                await updateDataAPI('POST', 'product-dev/clear-errors-all-upload-tickets/', {
                    storeID: filteringStore,
                });
                setTickets(prev => prev.map(t => {
                    if (t.store === filteringStore) {
                        return {...t, hasError: false, failedReason: null};
                    }
                    return t;
                }));
            }
            setLoading(false);
        }
    }

    return (
        <Container className="upload-tickets-page" fluid>
            <div className="filter">
                <div className="filter-left">
                    <div>
                        <FilterByStore filteringStore={filteringStore} setFilteringStore={setFilteringStore} />
                        <Form.Select value={filteredActionType} onChange={e => setFilteredActionType(e.target.value)}>
                            <option value="all">All</option>
                            <option value="UPLOAD_NEW_PRODUCT">UPLOAD NEW PRODUCT</option>
                            <option value="UPDATE_INFO">UPDATE INFO</option>
                            <option value="SWITCH_FREE_AND_PAID">SWITCH FREE AND PAID</option>
                        </Form.Select>
                    </div>
                </div>
                <div className="filter-right">
                    {filteringStore !== 'all' && (
                        <Button size="sm" onClick={() => {
                            fetchPendingUploadTickets();
                        }}>Refresh</Button>
                    )}
                    {filteringStore !== 'all' && (
                        <div>
                            <Button size="sm" variant="danger" onClick={() => clearErrors()}>Clear All Errors</Button>
                        </div>
                    )}
                </div>
            </div>
            {(filteringStore !== 'all') ? (
                <div className="tickets">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Ticket</th>
                                <th>Type</th>
                                <th>Idea</th>
                                <th>Error</th>
                                <th>Nb Fails</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tickets.filter(ticket => {
                                if (filteringStore !== 'all' && ticket.store !== filteringStore) {
                                    return false;
                                }

                                if (filteredActionType !== 'all' && ticket.actionType !== filteredActionType) {
                                    return false;
                                }

                                return true;
                            }).map((ticket, index) => (
                                <tr key={ticket.id}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <a href={Constant.STUDIO_API + 'admin/product_dev/uploadticket/' + ticket.id} target="_blank">
                                            {ticket.id}
                                        </a>
                                    </td>
                                    <td>
                                        <div>{ticket.actionType}</div>
                                        {ticket.actionType === 'UPDATE_INFO' && (
                                            <Form.Text>{ticket.toUpdateFields.join(', ')}</Form.Text>
                                        )}
                                        {ticket.buildRequired && (
                                            <div>
                                                {ticket.built ? (
                                                    <Badge bg="success">BUILT</Badge>
                                                ) : (
                                                    <Badge bg="warning">Pending</Badge>
                                                )}
                                            </div>
                                        )}
                                        {ticket.actionType === 'UPLOAD_NEW_PRODUCT' && ticket.toUploadProduct && (
                                            <Form.Text>{ticket.toUploadProduct.join(', ')}</Form.Text>
                                        )}
                                    </td>
                                    <td className="idea-title-cell">
                                        <div onClick={() => setIdeaInIdeaModal(ticket.productIdeaData)}>
                                            {ticket.productIdeaData.customID}. {ticket.productIdeaData.title}
                                        </div>

                                        <div className="actions">
                                            <a href={'/content-studio/?customID=' + ticket.productIdeaData.customID} target="_blank">
                                                <FontAwesomeIcon icon={faRobot} />
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        {ticket.hasError && (
                                            <div>{ticket.failedReason}</div>
                                        )}
                                    </td>
                                    <td>
                                        <div>{ticket.nbFails} fails</div>
                                    </td>
                                    <td>
                                        <div className="btns">
                                            <Button size="sm" variant="danger" onClick={() => clearErrors(ticket.id)}>Clear Error</Button>
                                            <Button size="sm" variant="danger" onClick={() => deleteTicket(ticket.id)}>Delete</Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </Table>
                </div>
            ) : (
                <IdeaTicketsStats setLoading={setLoading} />
            )}

            {ideaInIdeaModal !== null && (
                <IdeaModal
                    idea={ideaInIdeaModal}
                    closeModal={() => {
                        setIdeaInIdeaModal(null);
                    }}
                />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="dark" />
                </div>
            )}
        </Container >
    )
}

export default UploadTicketsPage;