import { useEffect, useState } from 'react';

import IdeasListTable from '../../components/idea-list/idea-list-table';
import IdeaModal from './idea-modal';
import { Button, ButtonGroup, Container, Dropdown, Form, ListGroup, Spinner } from 'react-bootstrap';
import FilterByCategory from '../../filters/category';
import { useSelector } from 'react-redux';
import Notification from '../../utils/notification';
import FilterByGrades from '../../filters/grade';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import Constant from '../../Constant';
import ReuploadFilesModal from '../../components/idea-list/re-upload-files-modal';
import FilterBySubjects from '../../filters/subject';
import { loadFonts, getIdeaToShowInModal } from '../../utils/commonUtil';
import StoreConfigModal from '../../components/idea-list/store-config-modal';
import NewStoreModal from './new-store-modal';

import './styles.scss';
import FilterByActivity from '../../filters/activity';
import FilterByStoreV0 from '../../filters/store';
import PagePagination from '../../components/auto-content/templates-management/page-pagination';
import FilterByTheme from '../../filters/theme';

const IdeaListPage = () => {
    const selectedStoreID = useSelector((state) => state.filters.selectedStoreID);
    const store = useSelector(state => state.filters.stores.find(s => s.id === state.filters.selectedStoreID));

    const [userRoles, setUserRoles] = useState([]);
    const [showCreateIdeaModal, setShowCreateIdeaModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ideas, setIdeas] = useState([]);
    const [notification, setNotification] = useState(null);
    const [selectedIdeaIDs, setSelectedIdeaIDs] = useState([]);
    const [showReuploadFilesModal, setShowReuploadFilesModal] = useState(false);
    const [editingIdea, setEditingIdea] = useState(null);
    const [fontFamilies, setFontFamilies] = useState([]);
    const [showAssetsStoreManagement, setShowAssetsStoreManagement] = useState(false);
    const [showNewStoreModal, setShowNewStoreModal] = useState(false);

    const [filteringActivity, setFilteringActivity] = useState('all');
    const [filteringTheme, setFilteringTheme] = useState('all');
    const [filteringUploadStatus, setFilteringUploadStatus] = useState('all');

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalIdeas, setTotalIdeas] = useState(0);

    useEffect(() => {
        fetchIdeas();
        setSelectedIdeaIDs([]);
        fetchFontFamilies();
    }, [selectedStoreID, currentPage]);

    useEffect(() => {
        setCurrentPage(1);
        setTimeout(() => fetchIdeas(), 1000);
    }, [filteringUploadStatus, filteringActivity, filteringTheme]);

    useEffect(() => {
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    const fetchIdeas = async () => {
        if (selectedStoreID === null) {
            return;
        }
        setLoading(true);
        let url = '';
        if (selectedStoreID !== null && !isNaN(selectedStoreID)) {
            url = 'product-ideas/?additionalFields=storeData,tasks,products&store=' + selectedStoreID + '&page=' + currentPage;
        } else if (selectedStoreID === 'all') {
            url = 'product-ideas/?additionalFields=storeData,tasks,products&page=' + currentPage;
        }

        if (filteringUploadStatus !== 'all') {
            url += '&uploadStatus=' + filteringUploadStatus;
        }

        if (filteringActivity !== 'all') {
            url += '&activity=' + filteringActivity;
        }

        if (filteringTheme !== 'all') {
            url += '&theme=' + filteringTheme;
        }
        const res = await getDataFromAPI(url);

        if (res) {
            const ideas = res.data['results'];
            ideas.sort((a, b) => {
                let regex = /^(\d+)([a-zA-Z]*)$/i;
                let matchA = a.customID.match(regex);
                let matchB = b.customID.match(regex);

                try {
                    let numA = parseInt(matchA[1], 10);
                    let numB = parseInt(matchB[1], 10);

                    if (numA === numB) {
                        return matchA[2].localeCompare(matchB[2]);
                    } else {
                        return numA - numB;
                    }
                } catch (err) {
                    return 1;
                }
            });
            setIdeas(ideas);
            setTotalPages(Math.ceil(res.data.count / 30));
            setTotalIdeas(res.data.count);
        }
        setLoading(false);
    }

    const fetchFontFamilies = async () => {
        const res = await getDataFromAPI('auto-content/font-families/');
        if (res.data) {
            for (const fontObj of res.data) {
                await loadFonts(fontObj);
            }
            setFontFamilies(res.data);
        }
    }

    const showIdeaModal = async (idea) => {
        const data = await getIdeaToShowInModal(idea.id);
        setEditingIdea(data);
    }

    const generateDescriptions = async() => {
        setLoading(true);
        for (let i = 0; i < selectedIdeaIDs.length; i++) {
            await updateDataAPI('POST', 'resources/make-idea-custom-action/', {
                action: 'generateDescription',
                ideaID: selectedIdeaIDs[i],
            });
        }
        setLoading(false);
    }

    const assignThumbnails = async() => {
        setLoading(true);
        for (let i = 0; i < selectedIdeaIDs.length; i++) {
            await updateDataAPI('POST', 'resources/make-idea-custom-action/', {
                action: 'assignThumbnail',
                ideaID: selectedIdeaIDs[i],
                options: ['paid'],
            });
        }
        setLoading(false);
    }

    return (
        <Container className="idea-list-container" fluid>
            <div className="filter">
                <div className="filter-left">
                    <div>
                        <FilterByStoreV0 hasAllOption={true} showNbIdeas={true} defaultStore="first" marketplace="boom" />
                        <FilterByCategory />
                        <FilterByGrades />
                        {/* <FilterBySearch /> */}
                        {/* <FilterByTaskStep /> */}
                        <Form.Select value={filteringUploadStatus} onChange={e => setFilteringUploadStatus(e.target.value)}>
                            <option value="all">All Status</option>
                            <option value="not_upload">Not Upload</option>
                            <option value="uploaded">Uploaded</option>
                        </Form.Select>
                        {selectedStoreID && (
                            <>
                                <FilterBySubjects storeID={selectedStoreID} />
                                <FilterByActivity filteringActivity={filteringActivity} setFilteringActivity={setFilteringActivity} />
                                <FilterByTheme filteringTheme={filteringTheme} setFilteringTheme={setFilteringTheme} />
                            </>
                        )}
                    </div>
                </div>
                <div className="filter-right">
                    <div>
                        {selectedStoreID && (
                            <Button variant="info" className="mt-2" onClick={() => setShowAssetsStoreManagement(true)}>
                                Config
                            </Button>
                        )}
                        {userRoles.indexOf('admin') !== -1 && selectedIdeaIDs.length > 0 && (
                            <Dropdown as={ButtonGroup} size="sm">
                                <Dropdown.Toggle variant="info" size="sm" id="make-custom-action-trigger">
                                    Make custom action
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setShowReuploadFilesModal(true)} variant="danger">Re-upload files</Dropdown.Item>
                                    <Dropdown.Item onClick={generateDescriptions} variant="danger">Generate Description</Dropdown.Item>
                                    <Dropdown.Item onClick={assignThumbnails} variant="danger">Assign PAID Thumbnails</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </div>
                </div>
            </div>

            {store && (
                <ListGroup className="store-info">
                    <ListGroup.Item>
                        <span>Subjects</span>
                        <span>
                            {store.boomSubjects ? store.boomSubjects : store.subjects}
                        </span>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <span>Grades</span>
                        <span>
                            {store.boomClass ? store.boomClass : store.grades}
                        </span>
                    </ListGroup.Item>
                </ListGroup>
            )}

            <IdeasListTable
                editingIdea={editingIdea}
                setEditingIdea={setEditingIdea}
                showIdeaModal={showIdeaModal}
                ideas={ideas}
                fetchIdeas={fetchIdeas}
                setIdeas={setIdeas}
                selectedIdeaIDs={selectedIdeaIDs}
                setSelectedIdeaIDs={setSelectedIdeaIDs}
                setLoading={setLoading}
                filteringActivity={filteringActivity}
                totalIdeas={totalIdeas}
            />

            <PagePagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={pageIndex => {
                    setCurrentPage(pageIndex);
                    fetchIdeas(pageIndex);
                }}
            />

            {showCreateIdeaModal && (
                <IdeaModal
                    closeModal={action => {
                        if (action === 'create') {
                            setNotification('Create successfully');
                        }
                        setShowCreateIdeaModal(false);
                        fetchIdeas();
                    }}
                    openEditIdeaModal={(idea) => showIdeaModal(idea)}/>
            )}
            {showReuploadFilesModal && (
                <ReuploadFilesModal
                    selectedProductIDs={selectedIdeaIDs}
                    setLoading={setLoading}
                    closeModal={action => setShowReuploadFilesModal(false)}
                />
            )}
            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
            {notification && (
                <Notification title={notification} closeNotification={() => setNotification(null)} />
            )}

            {showAssetsStoreManagement && selectedStoreID && (
                <StoreConfigModal
                    storeID={selectedStoreID}
                    fontFamilies={fontFamilies}
                    closeModal={() => setShowAssetsStoreManagement(false)}
                />
            )}

            {showNewStoreModal && (
                <NewStoreModal hideModal={() => setShowNewStoreModal(false)} />
            )}
        </Container>
    );
}

export default IdeaListPage;