import { useState } from 'react';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import AddNewPageButton from '../add-new-page-button';
import IdeaTemplatesModal from '../../auto-content/idea-templates-modal';
import IdeaSettingModal from '../../auto-content/idea-setting-modal';
import TableOfContentsModal from '../table-of-contents-modal';
import { updateDataAPI } from '../../../utils/query';
import UploadFile from '../upload-file';

import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const ContentStudioHeaderBtns = ({ idea, setIdea, pages, setPages, refresh, setLoading, setShowNonAIGenerateModal, setSelectedIdeaSkill, syncFromSource }) => {
    const [showSettingModal, setShowSettingModal] = useState(false);
    const [showIdeaTemplatesModal, setShowIdeaTemplatesModal] = useState(false);
    const [showTableOfContentsModal, setShowTableOfContentsModal] = useState(false);
    const [showJSONContentModal, setShowJSONContentModal] = useState(false);
    const [jsonContentInModal, setJSONContentInModal] = useState(null);

    const downloadImagesFromDrive = async () => {
        setLoading(true);
        await updateDataAPI('POST', 'product-dev/create-idea-pages-from-original-file/?customID=' + idea.customID);
        setLoading(false);
        window.location.reload();
    }

    const insertPagesFromAnotherIdea = async() => {
        let customIDs = window.prompt('Idea IDs, separate by ,. Example 59239,2929,1292', '');
        if (!customIDs) return;
        customIDs = customIDs.split(',');
        setLoading(true);
        for (let i = 0; i < customIDs.length; i++) {
            await updateDataAPI('POST', 'resources/insert-pages-from-another-idea/', {
                sourceCustomID: customIDs[i].trim(),
                targetCustomID: idea.customID,
            });
        }
        setLoading(false);
        window.location.reload();
    }

    const estimateNbAnswerKeyPages = async() => {
        setLoading(true);
        const res = await updateDataAPI('POST', 'resources/estimate-nb-answer-key-pages/?customID=' + idea.customID);
        window.alert('answer key file has ' + res.data['nbAnswerKeyPages'] + ' pages');
        setLoading(false);
    }

    return (
        <div className="content-studio-header-btns">
            <Button variant="info" onClick={() => setShowSettingModal(true)}>Setting</Button>
            <Button variant="secondary" onClick={() => setShowIdeaTemplatesModal(true)}>Templates</Button>
            <Button variant="info" onClick={() => setShowTableOfContentsModal(true)}>Table of Contents</Button>
            <AddNewPageButton idea={idea} pages={pages} setPages={setPages} refresh={refresh}
                setLoading={setLoading} placement="bottom" />
            <Dropdown>
                <Dropdown.Toggle variant="light" id="menu-btn">
                    <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={downloadImagesFromDrive}>Download Images</Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                        setShowJSONContentModal(true);
                        setJSONContentInModal(JSON.stringify(pages.map(page => page.content.aiResponse), null, 4));
                    }}>Export JSON</Dropdown.Item>
                    <Dropdown.Item onClick={() => syncFromSource()}>Sync From Source</Dropdown.Item>
                    <hr />
                    <Dropdown.Item onClick={() => document.getElementById('fileUpload').click()}>Upload AI File</Dropdown.Item>
                    {idea.originalDesignFile && (
                        <Dropdown.Item href={idea.originalDesignFile}>Download AI File</Dropdown.Item>
                    )}
                    <hr />
                    <Dropdown.Item onClick={insertPagesFromAnotherIdea}>Insert Pages from Idea</Dropdown.Item>
                    <Dropdown.Item onClick={estimateNbAnswerKeyPages}>Estimate Nb Answer Key Pages</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <div className="upload-ai-file-container">
                <UploadFile
                    label="Upload AI File"
                    id={idea['id']}
                    setLoading={setLoading}
                    uploadUrl={'resources/product-idea/upload-design-file/'}
                    allowedFileExtentions={['ai']}
                    fieldName={'originalDesignFile'}
                    uploadedFileName={null}
                    setUploadedFileName={fileName => setIdea((prev) => ({ ...prev, originalDesignFile: fileName }))}
                />
            </div>

            {showSettingModal && (
                <IdeaSettingModal
                    idea={idea}
                    hideModal={() => setShowSettingModal(false)} />
            )}

            {showIdeaTemplatesModal && (
                <IdeaTemplatesModal
                    idea={idea}
                    pages={pages.filter(page => page.type === 'auto')}
                    hideModal={() => setShowIdeaTemplatesModal(false)}
                />
            )}

            {showTableOfContentsModal && (
                <TableOfContentsModal
                    idea={idea}
                    pages={pages}
                    setPages={setPages}
                    hideModal={() => setShowTableOfContentsModal(false)}
                    refresh={refresh}
                    setShowNonAIGenerateModal={setShowNonAIGenerateModal}
                    setSelectedIdeaSkill={setSelectedIdeaSkill}
                />
            )}

            {showJSONContentModal && (
                <Modal
                    show={true}
                    size="lg"
                    onHide={() => {
                        setShowJSONContentModal(false);
                        setJSONContentInModal(null);
                    }}
                    className="json-content-modal"
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control as="textarea" value={jsonContentInModal} disabled={true} rows={20} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => navigator.clipboard.writeText(jsonContentInModal)}>Copy</Button>
                    </Modal.Footer>
                </Modal>
            )}

        </div>
    )
}

export default ContentStudioHeaderBtns;