import ImageWrapper from '../../../common/image-wrapper';

import './styles.scss';

const DataSetRowImagesPreview = ({ row, setShowDataSetImagesEditorModal, setActiveImageIndexInModal }) => {
    return (
        <div className="dataset-row-images-preview">
            {'icon' in row.content && row.content['icon'].indexOf('https') > -1 && (
                <img
                    className="row-icon"
                    src={row.content['icon']}
                    onClick={() => {
                        setShowDataSetImagesEditorModal(true);
                        setActiveImageIndexInModal(row.rowIndex);
                    }}
                />
            )}
            {'question' in row.content && row.content['question'].indexOf('https') > -1 && (
                <img
                    className="row-icon"
                    src={row.content['question']}
                    onClick={() => {
                        setShowDataSetImagesEditorModal(true);
                        setActiveImageIndexInModal(row.rowIndex);
                    }}
                />
            )}
            {'choices' in row.content && (
                <div className="choice-images">
                    {row.content['choices'].filter(choice => choice.startsWith('http')).map((choice, choiceIndex) => (
                        <ImageWrapper key={choiceIndex} src={choice} />
                    ))}
                </div>
            )}
            {'correct_choice' in row.content && row.content['correct_choice'].startsWith('http') && (
                <div className="correct-choice-image">
                    <ImageWrapper src={row.content['correct_choice']} />
                </div>
            )}
        </div>
    );
}
export default DataSetRowImagesPreview;