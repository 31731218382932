import { Col, Form, Row } from 'react-bootstrap';

const IdeaModalSaleTab = ({star, setStar}) => {
    return (
        <Row className="mt-3">
            <Col>
                <Form.Group className="mb-3" controlId="ideaStar">
                    <Form.Check type="checkbox" label="Star" checked={star} onChange={e => setStar(e.target.checked)} />
                </Form.Group>
            </Col>
            <Col>
            </Col>
        </Row>
    )
}

export default IdeaModalSaleTab;