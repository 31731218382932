import { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import {
    faCheck,
    faCheckCircle,
    faCloudUpload,
    faEdit,
    faStar,
    faTrash,
    faUpload,
    faVoicemail,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateDataAPI } from '../../../utils/query';
import { ButtonGroup, Dropdown, Form } from 'react-bootstrap';
import UploadFile from '../../content-studio/upload-file';
import DataSetImagesEditorModal from './dataset-images-editor-modal';
import DataSetRowsControlModal from './dataset-rows-control-modal';
import DataSetRowImagesPreview from './dataset-row-images-preview';

const DataSetData = ({
    dataSet,
    rows,
    setRows,
    fetchAllRows,
    updateIcons,
    updateMultiIconsPerRow,
    setLoading,
}) => {
    // const topicNames = useMemo(() => getTopicNamesFromDataSetItems(input), [dataSet?.topics]);

    const [topics, setTopics] = useState([]);
    const [dataSetIcons, setDataSetIcons] = useState([]);
    const [showItemsContent, setShowItemsContent] = useState(false);
    const [editingRow, setEditingRow] = useState(null);
    const [editingJSON, setEditingJSON] = useState(null);

    const [iconUploading, setIconUploading] = useState(false);
    const [uploadedIconUrl, setUploadedIconUrl] = useState(null);
    const [showDataSetImagesEditorModal, setShowDataSetImagesEditorModal] = useState(false);
    const [activeImageIndexInModal, setActiveImageIndexInModal] = useState(null);

    const [selectedRowIDs, setSelectedRowIDs] = useState([]);
    const [showRowsControlModal, setShowRowsControlModal] = useState(false);

    useEffect(() => {
        const id = (new URLSearchParams(window.location.search)).get('id');
        if (id) {
            setShowItemsContent(true);
        }
    }, []);

    // useEffect(() => {
    //     const fetchTopicsByName = async () => {
    //         if (topicNames.length > 0) {
    //             try {
    //                 const url = 'resources/fetch-topics-by-name/';

    //                 const res = await updateDataAPI('POST', url, { topicNames });

    //                 setTopics(res.data);

    //                 await fetchDataSetIcons(res.data);
    //             } catch (err) {
    //                 console.error(`An error occured when fetching topics by name: ${err.message}`);
    //             };
    //         } else {
    //             setTopics([]);
    //         };
    //     }

    //     fetchTopicsByName();
    // }, [topicNames]);

    const fetchDataSetIcons = async (listTopics = []) => {
        if (listTopics.length > 0 && dataSet?.id) {
            try {
                const url = 'resources/fetch-data-set-icons-by-topics-and-data-set/';

                const res = await updateDataAPI('POST', url, {
                    topicIDs: listTopics.map((topic) => topic.id),
                    dataSetID: dataSet.id,
                });

                setDataSetIcons(res.data);
            } catch (err) {
                console.error(`An error occured when fetching topic group icons: ${err.message}`);
            };
        }
    }

    const deleteRow = async (rowID) => {
        setLoading(true);
        await updateDataAPI('DELETE', 'resources/data-set-rows/' + rowID + '/');
        fetchAllRows();
        setLoading(false);
    }

    const toggleRowHighlight = async(row) => {
        setLoading(true);
        await updateDataAPI('PATCH', 'resources/data-set-rows/' + row.id + '/', {
            highlight: !row.highlight,
        });
        setRows(rows.map(r => {
            if (r.id === row.id) {
                return {...row, highlight: !row.highlight};
            }
            return r;
        }));
        setLoading(false);
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey || event.metaKey) {
                if (event.key === 'p') {
                    event.preventDefault();
                    setShowRowsControlModal(true);
                }
            }
        };
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    // const changeIconCustomTypePhoto = async (rowIndex, cellIndex, cellItemIndex, photoType) => {
    //     const updatedCellValues = [...input];
    //     let url;
    //     if (Array.isArray(updatedCellValues[rowIndex][cellIndex])) {
    //         url = updatedCellValues[rowIndex][cellIndex][cellItemIndex];
    //     } else {
    //         url = updatedCellValues[rowIndex][cellIndex];
    //     }
    //     if (photoType === 'real') {
    //         if (url.indexOf('customTypePhoto') === -1) {
    //             url += '?customTypePhoto=real';
    //         } else {
    //             url = url.replace('customTypePhoto=cartoon', 'customTypePhoto=real');
    //         }
    //     } else {
    //         if (url.indexOf('customTypePhoto') === -1) {
    //             url += '?customTypePhoto=cartoon';
    //         } else {
    //             url = url.replace('customTypePhoto=real', 'customTypePhoto=cartoon');
    //         }
    //     }
    //     if (Array.isArray(updatedCellValues[rowIndex][cellIndex])) {
    //         updatedCellValues[rowIndex][cellIndex][cellItemIndex] = url;
    //     } else {
    //         updatedCellValues[rowIndex][cellIndex] = url;
    //     }
    //     const dataSetData = {
    //         ...dataSet,
    //         topics: updatedCellValues,
    //     };

    //     await handleSaveDataSetChanges(dataSetData);
    // }

    // const moveRowToTop = async (rowIndex) => {
    //     setLoading(true);
        // const updatedCellValues = [...input];
        // let [item] = updatedCellValues.splice(rowIndex, 1);
        // updatedCellValues.unshift(item);
        // const dataSetData = {
        //     ...dataSet,
        //     topics: updatedCellValues,
        // };
        // await updateDataAPI('PATCH', 'resources/data-sets/' + dataSet.id + '/', {
        //     topics: updatedCellValues,
        // });

        // await handleSaveDataSetChanges(dataSetData);
    //     setLoading(false);
    // }

    // const moveRowToBottom = async (rowIndex) => {
    //     setLoading(true);
        // const updatedCellValues = [...input];
        // let [item] = updatedCellValues.splice(rowIndex, 1);
        // updatedCellValues.push(item);
        // const dataSetData = {
        //     ...dataSet,
        //     topics: updatedCellValues,
        // };
        // await updateDataAPI('PATCH', 'resources/data-sets/' + dataSet.id + '/', {
        //     topics: updatedCellValues,
        // });

        // await handleSaveDataSetChanges(dataSetData);
    //     setLoading(false);
    // }

    const deleteAllRows = async () => {
        const confirmed = window.confirm('Are you sure want to remove all this rows?');

        if (confirmed) {
            for (let i = 0; i < rows.length; i++) {
                await updateDataAPI('DELETE', 'resources/data-set-rows/' + rows[i].id + '/');
            }
            setRows([]);
        }
    }

    // const handleUpdatedTopic = (updatedTopic) => {
    //     setTopics((prev) => prev.map((t) => {
    //         if (t.id === updatedTopic.id) return updatedTopic;
    //         return t;
    //     }));
    // }

    // const handleChangeDataSetIcon = (updatedDataSetIcon) => {
    //     if (updatedDataSetIcon) {
    //         setDataSetIcons((prev) => prev.map((dataSetIcon) => {
    //             if (dataSetIcon.id === updatedDataSetIcon.id) {
    //                 return updatedDataSetIcon;
    //             }
    //             return dataSetIcon;
    //         }));
    //     }
    // }

    // const updateTopicIcon = async (topicID, iconID, url) => {
    //     const res = await updateDataAPI('POST', 'resources/update-topic-icon/', {
    //         dataSetID: dataSet.id,
    //         topicID,
    //         iconID,
    //     });
    //     setTopics((prev) => prev.map((topic) => {
    //         if (topic.id === topicID) {
    //             return { ...topic, imageUrl: url };
    //         }
    //         return topic;
    //     }));
    //     if (dataSetIcons.find((dataSetIcon) => dataSetIcon.topic === topicID)) {
    //         setDataSetIcons((prev) => prev.map((dataSetIcon) => {
    //             if (dataSetIcon.topic === topicID) {
    //                 return res.data;
    //             }
    //             return dataSetIcon;
    //         }));
    //     } else {
    //         setDataSetIcons(dataSetIcons.concat(res.data));
    //     }
    //     setSearchingWordID(null);
    // }

    const updateJSON = async() => {
        setLoading(true);
        const jsonObj = JSON.parse(editingJSON);
        const res = await updateDataAPI('PATCH', 'resources/data-set-rows/' + editingRow.id + '/', {
            content: jsonObj
        });
        setRows(rows.map(row => {
            if (row.id === editingRow.id) {
                return res.data;
            }
            return row;
        }));
        setEditingRow(null);
        setEditingJSON(null);
        setLoading(false);
    }

    const playAudio = async(audioUrl) => {
        const audio = new Audio(audioUrl);
        audio.play();
    }

    const genAudio = async(rowID) => {
        setLoading(true);
        try {
            const res = await updateDataAPI('POST', 'resources/gen-audio-for-dataset/', {
                dataSetID: dataSet.id,
                clear: true,
                rowID,
            });
            setRows(rows.map(r => {
                if (res.data[r.id]) {
                    return {...r, content: {...r.content, audio: res.data[r.id]}};
                }
                return r;
            }));
        } catch (err) {
            window.alert('check dataset activity, only support MCQ');
        }
        setLoading(false);
    }

    // const recoverOriginalIcon = async(rowIndex) => {
    //     setLoading(true);
    //     const newData = dataSet.topics.map((row, rIndex) => {
    //         if (rowIndex === rIndex) {
    //             return {...row, icon: row['original_icon']};
    //         }
    //         return row;
    //     })
    //     await updateDataAPI('PATCH', 'resources/data-sets/' + dataSet.id + '/', {
    //         topics: newData,
    //     });
    //     await handleSaveDataSetChanges({ ...dataSet, topics: newData });
    //     setLoading(false);
    // }

    const convertMathToImages = async(rowIDs) => {
        if (window.confirm('Are you sure want to convert all math to images?')) {
            setLoading(true);
            let result = {};
            const batchSize = 5;
            for (let i = 0; i < rowIDs.length; i += batchSize) {
                const batch = rowIDs.slice(i, i + batchSize);
                try {
                    const res = await updateDataAPI('POST', 'resources/create-dataset-images-for-math-items/', {
                        dataSetID: dataSet.id,
                        rowIDs: batch,
                    });
                    result = { ...result, ...res.data };
                } catch (err) {

                }
            }
            setRows(rows.map(r => {
                if (rowIDs.indexOf(r.id) > -1 && result[r.id]) {
                    return {...r, content: result[r.id]};
                }
                return r;
            }));
            window.alert('Convert ' + Object.keys(result).length + '/' + rowIDs.length + ' rows successfully');
            setLoading(false);
        }
    }

    return (
        <div>
            <div className="table-header-btns">
                {dataSet.nbQuestionsValidated > 0 && (
                    <Button size="sm" onClick={() => {
                        setSelectedRowIDs(rows.slice(0, dataSet.nbQuestionsValidated).map(row => row.id));
                    }}>Select all validated rows</Button>
                )}
                {selectedRowIDs.length > 0 && (
                    <Button size="sm" onClick={() => convertMathToImages(selectedRowIDs)}>Convert all {selectedRowIDs.length} selected rows to images</Button>
                )}
            </div>
            <Table striped bordered hover responsive>
                <tbody>
                    {rows.map(row => (
                        <tr key={row.id} id={'row-' + row.rowIndex}>
                            <td>
                                <span>{row.rowIndex + 1}</span>
                                {row.rowIndex < dataSet.nbQuestionsValidated && (
                                    <div className="check-icon"><FontAwesomeIcon icon={faCheckCircle} /></div>
                                )}

                                <Form.Check
                                    type="checkbox"
                                    onChange={() => {
                                        if (selectedRowIDs.indexOf(row.id) > -1) {
                                            setSelectedRowIDs(selectedRowIDs.filter(id => id !== row.id));
                                        } else {
                                            setSelectedRowIDs([...selectedRowIDs, row.id]);
                                        }
                                    }}
                                    checked={selectedRowIDs.indexOf(row.id) > -1} />
                            </td>
                            <td>
                                {editingRow?.id === row.id ? (
                                    <div>
                                        <Form.Control as="textarea" rows={10} value={editingJSON} onChange={e => setEditingJSON(e.target.value)} />
                                    </div>
                                ) : (
                                    <pre>{JSON.stringify(row.content, null, 4)}</pre>
                                )}
                            </td>
                            {/* {Array.from({ length: maxColumnsData - row.length }).map((_, index) => (
                                <td key={index} className="data-cell"></td>
                            ))} */}
                            <td className="btns-cell">
                                <DataSetRowImagesPreview
                                    row={row}
                                    setShowDataSetImagesEditorModal={setShowDataSetImagesEditorModal}
                                    setActiveImageIndexInModal={setActiveImageIndexInModal}
                                />
                                <div>
                                    <Button variant={row.highlight ? 'warning' : 'secondary'} size="sm" onClick={() => toggleRowHighlight(row)}>
                                        <FontAwesomeIcon icon={faStar} />
                                    </Button>
                                    {/* <Button variant="secondary" size="sm" onClick={() => moveRowToTop(rowIndex)}>
                                        <FontAwesomeIcon icon={faAngleDoubleUp} />
                                    </Button>
                                    <Button variant="secondary" size="sm" onClick={() => moveRowToBottom(rowIndex)}>
                                        <FontAwesomeIcon icon={faAngleDoubleDown} />
                                    </Button> */}
                                    <Button variant="danger" size="sm" onClick={() => deleteRow(row.id)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>

                                    <Dropdown as={ButtonGroup} size="sm">
                                        {editingRow?.id !== row.id ? (
                                            <Button variant="secondary" size="sm" onClick={() => {
                                                setEditingJSON(JSON.stringify(row.content, null, 4));
                                                setEditingRow(row);
                                            }}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                        ) : (
                                            <Button variant="secondary" size="sm" onClick={updateJSON}>
                                                <FontAwesomeIcon icon={faCheck} />
                                            </Button>
                                        )}

                                        <Dropdown.Toggle split id="edit-button-dropdown" variant="secondary" />

                                        <Dropdown.Menu>
                                            <Dropdown.Item size="sm" variant="secondary" onClick={() => convertMathToImages([row.id])}>Convert Math to Images</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>
                                <div>
                                    <Button size="sm" variant="secondary" onClick={() => document.querySelector('.ds-' + dataSet.id + '-' + row.id + '-upload input[type=file]').click()} className="upload-icon-btn">
                                        <FontAwesomeIcon icon={faUpload} />
                                        <div>
                                            <UploadFile
                                                id={dataSet.id}
                                                className={'ds-' + dataSet.id + '-' + row.id + '-upload'}
                                                setLoading={setIconUploading}
                                                uploadUrl={'resources/upload-data-set-icons/'}
                                                allowedFileExtentions={['png', 'jpg', 'jpeg']}
                                                fieldName={'temp'}
                                                uploadedFileName={null}
                                                setUploadedFileName={data => {
                                                    navigator.clipboard.writeText(Object.values(data)[0]);
                                                    updateIcons({[row.id + 1]: Object.values(data)[0]});
                                                }}
                                                multiple={true}
                                            />
                                            {iconUploading ? (
                                                <div>Uploading...</div>
                                            ) : (
                                                uploadedIconUrl && (
                                                    <div className="uploaded-icon-url">
                                                        <img src={uploadedIconUrl} />
                                                        <Form.Control value={uploadedIconUrl} disabled />
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </Button>
                                    <Button size="sm" variant="secondary" onClick={() => document.querySelector('.ds-' + dataSet.id + '-' + row.id + '-upload-multi input[type=file]').click()} className="multi-upload-icon-btn">
                                        <FontAwesomeIcon icon={faCloudUpload} />
                                        <div>
                                            <div>many</div>
                                            <UploadFile
                                                id={dataSet.id}
                                                className={'ds-' + dataSet.id + '-' + row.id + '-upload-multi'}
                                                setLoading={setIconUploading}
                                                uploadUrl={'resources/upload-data-set-icons/'}
                                                allowedFileExtentions={['png', 'jpg', 'jpeg']}
                                                fieldName={'temp'}
                                                uploadedFileName={null}
                                                setUploadedFileName={data => {
                                                    console.log(data);
                                                    updateMultiIconsPerRow(row.id, Object.values(data));
                                                }}
                                                multiple={true}
                                            />
                                            {iconUploading ? (
                                                <div>Uploading...</div>
                                            ) : (
                                                uploadedIconUrl && (
                                                    <div className="uploaded-icon-url">
                                                        <img src={uploadedIconUrl} />
                                                        <Form.Control value={uploadedIconUrl} disabled />
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </Button>
                                    {/* {row['icon'] && row['original_icon'] && (
                                        <Button size="sm" variant="secondary" onClick={() => recoverOriginalIcon(rowIndex)}>
                                            <FontAwesomeIcon icon={faRotateLeft} />
                                        </Button>
                                    )} */}
                                </div>
                                <div>
                                    <Dropdown as={ButtonGroup} size="sm">
                                        <Button variant={row.content['audio'] ? 'info' : 'secondary'} size="sm" onClick={() => {
                                            if (row.content['audio']) {
                                                playAudio(row.content['audio']);
                                            }
                                        }}>
                                            <FontAwesomeIcon icon={faVoicemail} />
                                        </Button>

                                        <Dropdown.Toggle split id="audio-mail-dropdown" variant="secondary" />

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => genAudio(row.id)}>Gen Audio</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </td>
                            {/* <DataSetTopicIcon
                                topic={topics[rowIndex]}
                                dataSetIcons={dataSetIcons}
                                searchingWordID={searchingWordID}
                                setSearchingWordID={setSearchingWordID}
                                updateTopicIcon={updateTopicIcon}
                                handleUpdatedTopic={handleUpdatedTopic}
                                handleChangeDataSetIcon={handleChangeDataSetIcon}
                            /> */}
                        </tr>
                    ))}
                </tbody>
            </Table>
            {rows.length > 0 && (
                <Button variant="danger" size="sm" onClick={deleteAllRows} className="remove-all-btn">
                    Remove all
                </Button>
            )}

            {showRowsControlModal && (
                <DataSetRowsControlModal
                    rows={rows}
                    hideModal={(action, selectedRowIndex) => {
                        setShowRowsControlModal(false);
                        if (action === 'focus') {
                            const element = document.getElementById('row-' + selectedRowIndex);
                            if (element) {
                                element.scrollIntoView({ behavior: 'smooth' });
                            }
                        }
                    }}
                />
            )}

            {showDataSetImagesEditorModal && (
                <DataSetImagesEditorModal
                    dataSet={dataSet}
                    defaultActiveImageIndex={activeImageIndexInModal}
                    hideModal={() => setShowDataSetImagesEditorModal(false)}
                    handleSaveDataSetChanges={() => {}}
                />
            )}
        </div>
    )
};

export default DataSetData;