import { useEffect, useState } from 'react';
import { Container, ListGroup, Table } from 'react-bootstrap';
import { getDataFromAPI } from '../../utils/query';
import LoadingComponent from '../../utils/loading-component';
import ImageWrapper from '../../components/common/image-wrapper';

import './styles.scss';

const KeywordRanksPage = () => {
    const [keywords, setKeywords] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchKeywords();
    }, []);

    const fetchKeywords = async () => {
        setLoading(true);
        const res = await getDataFromAPI('ranks/get-keywords-with-rank/');
        setKeywords(res.data);
        setLoading(false);
    }

    return (
        <Container className="keyword-ranks-page" fluid>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Last Crawl</th>
                        <th>Keyword</th>
                        <th>Nb Sales</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {keywords.map(keyword => (
                        <tr key={keyword.id}>
                            <td>{keyword.lastCrawl}</td>
                            <td>{keyword.keyword.name}</td>
                            <td>{keyword.keyword.nbBoomSales}</td>
                            <td>
                                <ListGroup className="ranks">
                                    {Object.keys(keyword.ranks).map(store => (
                                        <ListGroup.Item key={store} className="rank">
                                            <div>
                                                <div>Store {store}</div>
                                                <div className="idea-title">
                                                    <a href={'/content-studio/?customID=' + keyword.ranks[store]['customID']} target="_blank">
                                                        {keyword.ranks[store]['customID']}. {keyword.ranks[store]['title']}
                                                    </a>
                                                </div>
                                                <div>Pos {keyword.ranks[store]['pos']}</div>
                                            </div>
                                            <ImageWrapper src={keyword.ranks[store]['thumbnail']} />
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <LoadingComponent loading={loading} />
        </Container>
    );
}

export default KeywordRanksPage;