import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import Constant from '../../../Constant';
import './styles.scss';
import { useSelector } from 'react-redux';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import { Dropdown, Spinner, Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCheck, faClose, faRefresh } from '@fortawesome/free-solid-svg-icons';
import ProductIdeaEventsOffcanvas from '../product-idea-events-offcanvas';
import { toast } from 'react-toastify';
import { hasPermission } from '../../../utils/auth';
import DuplicateOptionsModal from '../duplicate-options-modal';
import FlowProgress from '../../../components/common/flow-progress';
import IdeaModalPriorityDifficultyTab from './priority-difficulty-tab';
import ThumbnailsPreviewsTab from './thumbnails-previews-tab';
import AnswerKeySheetTab from './answer-key-sheet-tab';
import IdeaModalInformationsTab from './informations-tab';
import IdeaModalAssigneesTab from './assignees-tab';
import IdeaModalTitleDescriptionTab from './title-description-tab';
import IdeaModalSaleTab from './sale-tab';

const IdeaModal = ({
    idea = null,
    duplicating = false,
    closeModal,
    openEditIdeaModal = () => {},
}) => {
    const { activityPermissions } = useSelector((state) => state.permissions);

    const [activeTab, setActiveTab] = useState('informations');
    const [loading, setLoading] = useState(false);
    const [stores, setStores] = useState(idea?.stores);
    const [customID, setCustomID] = useState(idea?.customID);
    const [edittingCustomID, setEdittingCustomID] = useState(false);
    const [edittingCustomIDError, setEditingCustomIDError] = useState(false);
    const [newCustomID, setNewCustomID] = useState(null);
    const [name, setName] = useState(idea?.name);
    const [collectors, setCollectors] = useState(idea?.collectors);
    const [designers, setDesigners] = useState(idea?.designers);
    const [informators, setInformators] = useState(idea?.informators);
    const [category, setCategory] = useState(idea?.category);
    const [title, setTitle] = useState(idea?.currentTitleEvent ? idea?.currentTitleEvent : idea?.title);
    const [dollarDealTitle, setDollarDealTitle] = useState(idea?.dollarDealTitle);
    const [titleReachMaxLength, setTitleReachMaxLength] = useState(false);
    const [titleFree, setTitleFree] = useState(idea?.titleFree);
    const [titleFreeReachMaxLength, setTitleFreeReachMaxLength] = useState(false);
    const [subjects, setSubjects] = useState(idea?.currentSubjectsEvent ? idea?.currentSubjectsEvent : idea?.subjects);
    const [grades, setGrades] = useState(idea?.grades ? idea.grades : 'homeschool');
    const [price, setPrice] = useState(idea?.price);
    const [standards, setStandards] = useState(idea?.standards);
    const [thumbnailTitle, setThumbnailTitle] = useState(idea?.thumbnailTitle);
    const [thumbnailSubTitle, setThumbnailSubTitle] = useState(idea?.thumbnailSubTitle);
    const [thumbnailSubTitle2, setThumbnailSubTitle2] = useState(idea?.thumbnailSubTitle2);
    const [prior, setPrior] = useState(idea ? idea.prior : false);
    const [qualityChecked, setQualityChecked] = useState(idea ? idea.qualityChecked : false);
    const [userRoles, setUserRoles] = useState([]);
    const [description, setDescription] = useState(idea?.currentDescriptionEvent ? idea?.currentDescriptionEvent : idea?.description);
    const [descriptionFree, setDescriptionFree] = useState(idea?.descriptionFree);
    const [mainKeyword, setMainKeyword] = useState(idea?.mainKeyword);
    const [subKeywords, setSubKeywords] = useState(idea?.subKeywords);
    const [note, setNote] = useState(idea?.note);
    const [noteEditorActive, setNoteEditorActive] = useState(idea ? false : true);
    const [estimatedNbPages, setEstimatedNbPages] = useState(idea?.estimatedNbPages);
    const [freePages, setFreePages] = useState(idea?.freePages);
    const [edittingFieldByEvent, setEdittingFieldByEvent] = useState(null);
    const [tags, setTags] = useState(idea?.tags.join(','));
    const [showDuplicateOptionsModal, setShowDuplicateOptionsModal] = useState(false);
    // const [isTitleVerified, setIsTitleVerified] = useState(true);
    // const [isTitleFreeVerified, setIsTitleFreeVerified] = useState(true);
    const [titleFreeWarning, setTitleFreeWarning] = useState('');
    const [titleWarning, setTitleWarning] = useState('');
    const [star, setStar] = useState(idea ? idea.star : false);

    // task props
    const [tasks, setTasks] = useState(idea?.tasks);
    const [task, setTask] = useState(null);
    const [advanced, setAdvanced] = useState();
    const [blocked, setBlocked] = useState();
    const [blockedReasonNote, setBlockedReasonNote] = useState();
    const [blockedReasonTitle, setBlockedReasonTitle] = useState('');
    const [priority, setPriority] = useState();
    const [createIdeaDifficulty, setCreateIdeaDifficulty] = useState();
    const [checkDataDifficulty, setCheckDataDifficulty] = useState();

    const [freeSkills, setFreeSkills] = useState([]);

    useEffect(() => {
        const userRoles = JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES));
        setUserRoles(userRoles);
        if (userRoles.indexOf('product-collector') > -1 && idea === null) {
            setCollectors(localStorage.getItem(Constant.LOCAL_STORAGE_STAFF_ID))
        }
        fetchTask();
        fetchFreeSkills();
    }, []);

    useEffect(() => {
        if (idea === null || duplicating) {
            getCustomIDForNewIdea();
        }
    }, [idea, duplicating]);

    // useEffect(() => {
    //     checkVerifyTitle(title, keywords, setTitleWarning, setIsTitleVerified);
    // }, [keywords, title]);

    // useEffect(() => {
    //     checkVerifyTitle(titleFree, keywords, setTitleFreeWarning, setIsTitleFreeVerified);
    // }, [keywords, titleFree]);

    // const checkVerifyTitle = (inputTitle, keywords, onTitleWarning = () => {}, onResultChecked = () => {}) => {
    //     if (inputTitle !== '' && inputTitle && keywords) {
    //         const keywordsArr = keywords.split(',');
    //         const verifiedValues = keywordsArr.filter(keyword => inputTitle.toLowerCase().includes(keyword.toLowerCase()));
    //         if (keywordsArr.length === 1 && verifiedValues.length === 0) {
    //             onTitleWarning("Title must contain the selected keyword");
    //             onResultChecked(false);
    //         } else if (keywordsArr.length >= 2 && verifiedValues.length < 2) {
    //             onTitleWarning("Title must contain at least two of the selected keywords");
    //             onResultChecked(false);
    //         } else {
    //             onResultChecked(true);
    //         }
    //     } else {
    //         onResultChecked(true);
    //     }
    // };

    const fetchTask = async () => {
        if (idea) {
            const res = await getDataFromAPI('product-dev/tasks/?productIdea=' + idea.id + '&additionalFields=history');
            if (res.data.length > 0) {
                const task = res.data[0];
                setAdvanced(task.advanced);
                setBlocked(task.blocked);
                setBlockedReasonNote(task.blockedReasonNote);
                setBlockedReasonTitle(task.blockedReasonTitle);
                setPriority(task.priority);
                setCreateIdeaDifficulty(task.createIdeaDifficulty);
                setCheckDataDifficulty(task.checkDataDifficulty);
                setTask(task);
            }
        } else {
            setAdvanced(false);
            setBlocked(false);
            setBlockedReasonNote(null);
            setPriority('normal');
            setCreateIdeaDifficulty('medium');
            setCheckDataDifficulty('easy');
        }
    }

    const fetchFreeSkills = async() => {
        if (idea) {
            const res = await getDataFromAPI('resources/idea-skills/?inFreeProduct=true&customID=' + idea.customID);
            console.log(res.data);
            setFreeSkills(res.data);
        }
    }

    const getCustomIDForNewIdea = async () => {
        const res = await getDataFromAPI('resources/get-new-customID/');
        setCustomID(res.data);
    }

    const refreshTaskProgress = async(taskID) => {
        const res = await getDataFromAPI('product-dev/tasks/' + taskID + '/');
        setTasks(tasks.map(task => {
            if (task.id === taskID) {
                return {...task, step: res.data.stepPos, stepPos: res.data.stepPos};
            }
            return task;
        }));
    }

    const update = async () => {
        setLoading(true);

        // if (title && title !== idea.title && (await checkTitleExist(title))) {
        //     toast.warn('Title exists, can not save. Please change the title');
        //     setLoading(false);
        //     return;
        // }

        const data = {
            customID,
            name,
            stores,
            collectors,
            designers,
            informators,
            category,
            title: title !== '' ? title : null,
            titleFree: titleFree !== '' ? titleFree : null,
            dollarDealTitle: dollarDealTitle !== '' ? dollarDealTitle : null,
            price: price !== '' ? price : null,
            grades: grades !== '' ? grades : null,
            subjects: subjects !== '' ? subjects : null,
            prior,
            qualityChecked,
            standards: standards !== '' ? standards : null,
            thumbnailTitle: thumbnailTitle !== '' ? thumbnailTitle : null,
            thumbnailSubTitle: thumbnailSubTitle !== '' ? thumbnailSubTitle : null,
            thumbnailSubTitle2: thumbnailSubTitle2 !== '' ? thumbnailSubTitle2 : null,
            description: description !== '' ? description : null,
            descriptionFree: descriptionFree !== '' ? descriptionFree : null,
            mainKeyword: mainKeyword !== '' ? mainKeyword : null,
            subKeywords: subKeywords !== '' ? subKeywords : null,
            note: note !== '' ? note : null,
            estimatedNbPages: estimatedNbPages !== '' ? estimatedNbPages : null,
            freePages: freePages !== '' ? freePages : null,
            star,
        }
        if (tags !== undefined && tags.length > 0) {
            data['tags'] = tags.split(',');
        } else {
            data['tags'] = [];
        }
        const res = await updateDataAPI('PATCH', 'product-ideas/' + idea.id + '/', data);
        if (task) {
            await updateDataAPI('PATCH', 'product-dev/tasks/' + task.id + '/', {
                advanced,
                blocked,
                blockedReasonNote,
                blockedReasonTitle,
                priority: priority !== '' ? priority : null,
                createIdeaDifficulty: createIdeaDifficulty !== '' ? createIdeaDifficulty : null,
                checkDataDifficulty: checkDataDifficulty !== '' ? checkDataDifficulty : null,
            });
        }
        setLoading(false);
        if (res.status === 200) {
            closeModal('update', res.data);
        }
    }

    const duplicate = async (withTemplates, withSkills, withThemes, withSettings, withData) => {
        if (customID && name) {
            setLoading(true);

            // if (title && (await checkTitleExist(title))) {
            //     toast.warn('Title exists, can not save. Please change the title');
            //     setLoading(false);
            //     return;
            // }

            const data = {
                customID,
                stores,
                name,
                category,
                title: title !== undefined ? title : null,
                titleFree: titleFree !== undefined ? titleFree : null,
                dollarDealTitle: dollarDealTitle !== undefined ? dollarDealTitle : null,
                price: price !== undefined ? price : null,
                grades: grades !== undefined ? grades : null,
                subjects: subjects !== undefined ? subjects : null,
                standards: standards !== undefined ? standards : null,
                thumbnailTitle: thumbnailTitle !== '' ? thumbnailTitle : null,
                thumbnailSubTitle: thumbnailSubTitle !== undefined ? thumbnailSubTitle : null,
                thumbnailSubTitle2: thumbnailSubTitle2 !== undefined ? thumbnailSubTitle2 : null,
                description: description !== undefined ? description : null,
                descriptionFree: descriptionFree !== undefined ? descriptionFree : null,
                mainKeyword: mainKeyword !== undefined ? mainKeyword : null,
                subKeywords: subKeywords !== undefined ? subKeywords : null,
                note: note !== undefined ? note : null,
                estimatedNbPages: estimatedNbPages !== undefined ? estimatedNbPages : null,
                prior,
                star,
                qualityChecked,
                freePages: freePages !== undefined ? freePages : null,
            };

            if (tags !== undefined && tags.length > 0) {
                data['tags'] = tags.split(',');
            } else {
                data['tags'] = [];
            }

            const res = await updateDataAPI('POST', 'product-ideas/', data);

            if (res.status === 201) {
                if (collectors) {
                    await updateDataAPI('PATCH', 'product-ideas/' + res.data['id'] + '/', {
                        collectors
                    });
                }
                if (designers) {
                    await updateDataAPI('PATCH', 'product-ideas/' + res.data['id'] + '/', {
                        designers
                    });
                }
                if (informators) {
                    await updateDataAPI('PATCH', 'product-ideas/' + res.data['id'] + '/', {
                        informators
                    });
                }
                await updateDataAPI('POST', 'product-dev/tasks/', {
                    productIdea: res.data['id'],
                    title: customID + ' - ' + name,
                    advanced,
                    blocked,
                    blockedReasonNote,
                    blockedReasonTitle,
                    priority: priority !== undefined ? priority : null,
                    createIdeaDifficulty: createIdeaDifficulty !== undefined ? createIdeaDifficulty : null,
                    checkDataDifficulty: checkDataDifficulty !== undefined ? checkDataDifficulty : null,
                    active: false,
                });

                if (withTemplates) {
                    await updateDataAPI('POST', 'resources/duplicate-idea-extra/', {
                        originalID: idea['id'],
                        newID: res.data['id'],
                        withSkills,
                        withThemes,
                        withSettings,
                        withData,
                    });
                }

                setLoading(false);
                closeModal('duplicate');
            } else {
                setLoading(false);
            }
        } else {
            window.alert('Điền các trường bắt buộc')
        }
    }

    const create = async () => {
        if (customID && name) {
            setLoading(true);

            try {
                await getDataFromAPI('resources/check-customID-exists/?customID=' + customID);
            } catch (err) {
                setLoading(false);
                window.alert('ID exists, please change');
                return;
            }

            if (price && isNaN(price)) {
                toast.warn('Please check the price, it should be a number (ex: 5 or 4.7)');
                setLoading(false);
                return;
            }

            if (title && (await checkTitleExist(title))) {
                toast.warn('Title exists, can not save. Please change the title');
                setLoading(false);
                return;
            }

            const data = {
                customID,
                stores,
                name,
                category,
                title: title !== undefined ? title : null,
                titleFree: titleFree !== undefined ? titleFree : null,
                dollarDealTitle: dollarDealTitle !== undefined ? dollarDealTitle : null,
                price: price !== undefined ? price : null,
                grades: grades !== undefined ? grades : null,
                subjects: subjects !== undefined ? subjects : null,
                standards: standards !== undefined ? standards : null,
                thumbnailTitle: thumbnailTitle !== undefined ? thumbnailTitle : null,
                thumbnailSubTitle: thumbnailSubTitle !== undefined ? thumbnailSubTitle : null,
                thumbnailSubTitle2: thumbnailSubTitle2 !== undefined ? thumbnailSubTitle2 : null,
                description: description !== undefined ? description : null,
                descriptionFree: descriptionFree !== undefined ? descriptionFree : null,
                mainKeyword: mainKeyword !== undefined ? mainKeyword : null,
                subKeywords: subKeywords !== undefined ? subKeywords : null,
                note: note !== undefined ? note : null,
                estimatedNbPages: estimatedNbPages !== undefined ? estimatedNbPages : null,
                prior,
                star,
                qualityChecked,
                freePages: freePages !== undefined ? freePages : null,
            };

            if (tags !== undefined && tags.length > 0) {
                data['tags'] = tags.split(',');
            }

            const res = await updateDataAPI('POST', 'product-ideas/', data);

            if (res.status === 201) {
                if (collectors) {
                    await updateDataAPI('PATCH', 'product-ideas/' + res.data['id'] + '/', {
                        collectors
                    });
                }
                if (designers) {
                    await updateDataAPI('PATCH', 'product-ideas/' + res.data['id'] + '/', {
                        designers
                    });
                }
                if (informators) {
                    await updateDataAPI('PATCH', 'product-ideas/' + res.data['id'] + '/', {
                        informators
                    });
                }
                await updateDataAPI('POST', 'product-dev/tasks/', {
                    productIdea: res.data['id'],
                    title: customID + ' - ' + name,
                    advanced,
                    blocked,
                    blockedReasonNote,
                    blockedReasonTitle,
                    priority: priority !== undefined ? priority : null,
                    createIdeaDifficulty: createIdeaDifficulty !== undefined ? createIdeaDifficulty : null,
                    checkDataDifficulty: checkDataDifficulty !== undefined ? checkDataDifficulty : null,
                });
                setLoading(false);
                const updatedIdeaRes = await getDataFromAPI(`product-ideas/${res.data['id']}`);
                closeModal('create');
                openEditIdeaModal(updatedIdeaRes.data);
            } else {
                setLoading(false);
            }
        } else {
            window.alert('Điền các trường bắt buộc')
        }
    }

    const deleteProduct = async () => {
        if (window.confirm('Bạn chắc chắn muốn xóa sản phẩm này chứ?')) {
            await updateDataAPI('DELETE', 'product-ideas/' + idea.id + '/');
            closeModal('delete');
        }
    }

    useEffect(() => {
        if (edittingCustomID) {
            setNewCustomID(customID);
        }
        setEditingCustomIDError(false);
    }, [edittingCustomID]);

    const checkAndChangeCustomID = async () => {
        try {
            const res = await getDataFromAPI('resources/check-customID-exists/?customID=' + newCustomID);
            if (res.status === 200) {
                setCustomID(newCustomID);
                setEdittingCustomID(false);
            } else {
                setEditingCustomIDError(true);
            }
        } catch (err) {
            setEditingCustomIDError(true);
        }
    }

    const checkTitleExist = async (title) => {
        const res = await updateDataAPI('POST', 'resources/product-idea/check-title-exist/', { stores, title });
        return res.data.result;
    }

    const openColorVersion = async() => {
        try {
            const res = await getDataFromAPI('resources/get-color-version-by-customID/?customID=' + customID);
            window.open('/content-studio/?customID=' + res.data['customID'], '_blank', 'noopener,noreferrer');
        } catch (err) {
            window.alert('no color version');
        }
    }

    const moveIdeaFromStoreToStore = async() => {
        const targetStoreID = window.prompt('Target Store ID', '');
        if (targetStoreID) {
            setLoading(true);
            await updateDataAPI('POST', 'resources/move-idea-from-store-to-store/', {
                customID: idea.customID,
                targetStoreID: targetStoreID,
            });
            closeModal('moveIdeaFromStoreToStore');
            setLoading(false);
        }
    }

    return (
        <div>
            <Modal show={true} backdrop="static" keyboard={false} onHide={() => closeModal('hide')} size="xl" className="edit-product-modal">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {idea ? (
                            <span>{duplicating ? 'New Product Version' : 'Edit Product'} </span>
                        ) : (
                            <span>New Product - Code:</span>
                        )}
                        {edittingCustomID && (
                            <Form className="change-custom-id-form">
                                <Form.Control value={newCustomID} onChange={e => setNewCustomID(e.target.value)} />
                                {newCustomID !== customID && (
                                    <FontAwesomeIcon icon={faCheck} onClick={checkAndChangeCustomID} />
                                )}
                                <FontAwesomeIcon icon={faClose} onClick={() => {
                                    setEdittingCustomID(false);
                                }} />
                            </Form>
                        )}
                        <span>{customID}</span>
                        {!edittingCustomID && (
                            <div className="customID-icons">
                                <FontAwesomeIcon title="Generate Code" icon={faRefresh} onClick={getCustomIDForNewIdea} />
                            </div>
                        )}
                    </Modal.Title>
                    {edittingCustomIDError && (
                        <div>Code existed</div>
                    )}
                </Modal.Header>
                <Modal.Body>
                    {idea && (
                        <div className="tasks-process">
                            {tasks.map(task => (
                                <div key={task.id}>
                                    <h2>Store {task.store}</h2>
                                    <FlowProgress
                                        task={task}
                                        refreshTaskProgress={() => setTimeout(() => refreshTaskProgress(task.id), 1500)}
                                        taskHistories={task.history}
                                        isStepActive={(step) => step.id === task.step}
                                        setLoading={setLoading}
                                        tags={tags}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="secondary-btns">
                        {tags && (tags.indexOf(Constant.TASKCARD_TAG) > -1 || tags.indexOf(Constant.COLOR_BY_CODE_TAG) > -1) && (
                            <Button size="sm" variant="secondary" onClick={openColorVersion}>Color Version</Button>
                        )}
                    </div>
                    <Form>
                        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} id="idea-modal-tabs" className="mt-5">
                            <Tab eventKey="informations" title="Informations">
                                <IdeaModalInformationsTab
                                    stores={stores}
                                    setStores={setStores}
                                    name={name}
                                    setName={setName}
                                    subjects={subjects}
                                    setSubjects={setSubjects}
                                    tags={tags}
                                    setTags={setTags}
                                    grades={grades}
                                    setGrades={setGrades}
                                    standards={standards}
                                    setStandards={setStandards}
                                    estimatedNbPages={estimatedNbPages}
                                    setEstimatedNbPages={setEstimatedNbPages}
                                    subKeywords={subKeywords}
                                    setSubKeywords={setSubKeywords}
                                    mainKeyword={mainKeyword}
                                    setMainKeyword={setMainKeyword}
                                    note={note}
                                    setNote={setNote}
                                    noteEditorActive={noteEditorActive}
                                    setNoteEditorActive={setNoteEditorActive}
                                    dataSets={idea?.dataSets}
                                    loading={loading}
                                    setLoading={setLoading}
                                />
                            </Tab>
                            <Tab eventKey="assignees" title="Assignees">
                                <IdeaModalAssigneesTab
                                    collectors={collectors}
                                    setCollectors={setCollectors}
                                    designers={designers}
                                    setDesigners={setDesigners}
                                    informators={informators}
                                    setInformators={setInformators}
                                />
                            </Tab>
                            <Tab eventKey="title_desc" title="Title & Description">
                                <IdeaModalTitleDescriptionTab
                                    customID={customID}
                                    stores={stores}
                                    title={title}
                                    setTitle={setTitle}
                                    titleReachMaxLength={titleReachMaxLength}
                                    setTitleReachMaxLength={setTitleReachMaxLength}
                                    titleWarning={titleWarning}
                                    titleFree={titleFree}
                                    setTitleFree={setTitleFree}
                                    titleFreeReachMaxLength={titleFreeReachMaxLength}
                                    setTitleFreeReachMaxLength={setTitleFreeReachMaxLength}
                                    titleFreeWarning={titleFreeWarning}
                                    dollarDealTitle={dollarDealTitle}
                                    setDollarDealTitle={setDollarDealTitle}
                                    freeSkills={freeSkills}
                                    description={description}
                                    setDescription={setDescription}
                                    descriptionFree={descriptionFree}
                                    setDescriptionFree={setDescriptionFree}
                                />
                            </Tab>
                            <Tab eventKey="thumbnails_previews" title="Thumbnails & Previews">
                                <ThumbnailsPreviewsTab
                                    customID={customID}
                                    freePages={freePages}
                                    thumbnails={idea?.thumbnails}
                                    stores={idea?.stores}
                                    thumbnailTitle={thumbnailTitle}
                                    setThumbnailTitle={setThumbnailTitle}
                                    thumbnailSubTitle={thumbnailSubTitle}
                                    setThumbnailSubTitle={setThumbnailSubTitle}
                                    thumbnailSubTitle2={thumbnailSubTitle2}
                                    setThumbnailSubTitle2={setThumbnailSubTitle2}
                                    highlightImages={idea?.highlightImages}
                                    setLoading={setLoading}
                                    tags={tags}
                                    updateIdea={update} />
                            </Tab>
                            <Tab eventKey="priorities_difficulties" title="Priority & Difficulty">
                                <IdeaModalPriorityDifficultyTab
                                    userRoles={userRoles}
                                    priority={priority}
                                    setPriority={setPriority}
                                    createIdeaDifficulty={createIdeaDifficulty}
                                    setCreateIdeaDifficulty={setCreateIdeaDifficulty}
                                    checkDataDifficulty={checkDataDifficulty}
                                    setCheckDataDifficulty={setCheckDataDifficulty}
                                    prior={prior}
                                    setPrior={setPrior}
                                    qualityChecked={qualityChecked}
                                    setQualityChecked={setQualityChecked}
                                    blocked={blocked}
                                    setBlocked={setBlocked}
                                    blockedReasonNote={blockedReasonNote}
                                    blockedReasonTitle={blockedReasonTitle}
                                    setBlockedReasonTitle={setBlockedReasonTitle}
                                    setBlockedReasonNote={setBlockedReasonNote} />
                            </Tab>
                            <Tab eventKey="answer_key_sheet" title="Answer Key & Answer Sheet">
                                <AnswerKeySheetTab customID={customID} setLoading={setLoading} />
                            </Tab>
                            <Tab eventKey="sale" title="Sale">
                                <IdeaModalSaleTab
                                    star={star}
                                    setStar={setStar}
                                />
                            </Tab>
                        </Tabs>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {loading ? (
                        <div className="loading-container">
                            <Spinner animation="border" variant="light" />
                        </div>
                    ) : (
                        <>
                            <Dropdown>
                                <Dropdown.Toggle variant="secondary">
                                    <FontAwesomeIcon icon={faBars} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {hasPermission(userRoles, activityPermissions['DUPLICATE_PRODUCT_IDEA']) && idea !== null && duplicating && (
                                        <Dropdown.Item onClick={() => setShowDuplicateOptionsModal(true)}>Duplicate</Dropdown.Item>
                                    )}
                                    {/* {hasPermission(userRoles, activityPermissions['MOVE_IDEA_FROM_STORE_TO_STORE']) && idea !== null && !duplicating && (
                                        <Dropdown.Item onClick={moveIdeaFromStoreToStore}>Move Idea To Store</Dropdown.Item>
                                    )} */}
                                    {hasPermission(userRoles, activityPermissions['DELETE_PRODUCT_IDEA']) && idea && !duplicating && (
                                        <Dropdown.Item onClick={deleteProduct}>Delete</Dropdown.Item>
                                    )}
                                    <Dropdown.Divider />
                                    {idea !== null && !duplicating && (
                                        <Dropdown.Item href={'/outline-idea/?customID=' + customID} target="_blank">Outline</Dropdown.Item>
                                    )}
                                    <Dropdown.Divider />
                                    {userRoles.indexOf('admin') > -1 && (
                                        <>
                                            {idea.paidFileDriveID && (
                                                <Dropdown.Item href={idea.paidFileDriveID.indexOf('http') > -1 ? idea.paidFileDriveID : ('https://drive.google.com/file/d/' + idea.paidFileDriveID)} target="_blank">Download PAID File</Dropdown.Item>
                                            )}
                                            {idea.freeFileDriveID && (
                                                <Dropdown.Item href={idea.freeFileDriveID.indexOf('http') > -1 ? idea.freeFileDriveID : ('https://drive.google.com/file/d/' + idea.freeFileDriveID)} target="_blank">Download FREE File</Dropdown.Item>
                                            )}
                                            {idea.previewFileDriveID && (
                                                <Dropdown.Item href={idea.previewFileDriveID.indexOf('http') > -1 ? idea.previewFileDriveID : ('https://drive.google.com/file/d/' + idea.previewFileDriveID)} target="_blank">Download PREVIEW File</Dropdown.Item>
                                            )}
                                        </>
                                    )}
                                    <Dropdown.Divider />
                                    {userRoles.indexOf('admin') > -1 && idea.relatedProducts && (
                                        <>
                                            {idea.relatedProducts.map((product, productIndex) => (
                                                <Dropdown.Item key={productIndex} href={product['url']} target="_blank" rel="noreferrer">{product['saleStatus']}</Dropdown.Item>
                                            ))}
                                        </>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                            <div>
                                {hasPermission(userRoles, activityPermissions['UPDATE_PRODUCT_IDEA']) && idea !== null && !duplicating && (
                                    <>
                                        <a href={'/content-studio/?customID=' + customID} target="_blank">Content Studio</a>
                                        <Button variant="primary" onClick={update}>Update</Button>
                                    </>
                                )}
                                {/* {hasPermission(userRoles, activityPermissions['CREATE_PRODUCT_IDEA']) && idea === null && (
                                    <Button variant="primary" onClick={create} disabled={!(customID && name && stores && category)}>Create</Button>
                                )} */}
                            </div>
                        </>
                    )}
                </Modal.Footer>
            </Modal>

            {edittingFieldByEvent !== null && <ProductIdeaEventsOffcanvas field={edittingFieldByEvent} productID={idea?.id} hideModal={() => setEdittingFieldByEvent(null)} />}

            {showDuplicateOptionsModal && (
                <DuplicateOptionsModal
                    duplicate={duplicate}
                    hideModal={() => setShowDuplicateOptionsModal(false)} />
            )}
        </div>
    );
}

export default IdeaModal;