import { Button, ButtonGroup, Dropdown, Table } from 'react-bootstrap';
import DataSetDesignRowTemplatePreview from './template-preview';
import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import DataSetDesignRowSelectTemplateModal from './select-template-modal';
import DataSetDesignRowPreview from './design-row-preview';

import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faVoicemail } from '@fortawesome/free-solid-svg-icons';
import Constant from '../../../../Constant';

const DataSetDesignRows = ({ dataSet, setLoading }) => {
    const [showNewDesignRowTemplateModal, setShowNewDesignRowTemplateModal] = useState(false);
    const [rows, setRows] = useState([]);
    const [userRoles, setUserRoles] = useState([]);

    useEffect(() => {
        fetchRows();
        setUserRoles(JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_ROLES)));
    }, []);

    const fetchRows = async() => {
        setLoading(true);
        setRows([]);
        setTimeout(async() => {
            const res = await getDataFromAPI('resources/data-set-design-rows/?dataSetID=' + dataSet.id);
            setRows(res.data);
        }, 0);
        setLoading(false);
    }

    const insertDesignRows = async (templates) => {
        setLoading(true);
        for (let i = 0; i < templates.length; i++) {
            await updateDataAPI('POST', 'resources/add-new-data-set-design-row/', {
                templateID: templates[i].id,
                dataSetID: dataSet.id,
            });
        }
        await fetchRows();
        setLoading(false);
        setShowNewDesignRowTemplateModal(false);
    }

    const deleteRow = async(rowID) => {
        setLoading(true);
        await updateDataAPI('POST', 'resources/delete-data-set-design-rows/', {
            rowIDs: [rowID],
            dataSetID: dataSet.id,
        });
        await fetchRows();
        setLoading(false);
    }

    const duplicateRow = async(rowID) => {
        setLoading(true);
        await updateDataAPI('POST', 'resources/duplicate-data-set-design-row/', {
            rowID,
        });
        await fetchRows();
        setLoading(false);
    }

    const refreshRow = async(rowID) => {
        setLoading(true);
        const res = await updateDataAPI('PATCH', 'resources/data-set-design-rows/' + rowID + '/', {
            lastChanged: new Date(),
        });
        setRows(prev => prev.map(r => {
            if (r['id'] === rowID) {
                return res.data;
            }
            return r;
        }));
        setLoading(false);
    }

    const moveRow = async(rowID, dir) => {
        setLoading(true);
        await updateDataAPI('POST', 'resources/change-design-row-index/', {
            rowID,
            dir,
        });
        await fetchRows();
        setLoading(false);
    }

    const playAudio = async(audioUrl) => {
        const audio = new Audio(audioUrl);
        audio.play();
    }

    const genAudio = async(rowID) => {
        setLoading(true);
        try {
            if (dataSet.activity === 'READING_COMPREHENSION_UNKNOWN_STORY') {
                await updateDataAPI('POST', 'resources/gen-audio-for-reading-comprehension-dataset/', {
                    dataSetID: dataSet.id,
                    clear: true,
                    rowID,
                });
            } else if (dataSet.activity === 'BOOK_COMPANION_STORY_GRAMMAR' || dataSet.activity === 'BOOK_COMPANION_GRAMMAR') {
                await updateDataAPI('POST', 'resources/gen-audio-for-book-companion-dataset/', {
                    dataSetID: dataSet.id,
                    clear: true,
                    rowID,
                });
            }
            await fetchRows();
        } catch (err) {
            window.alert('check dataset activity, only support MCQ');
        }
        setLoading(false);
    }

    return (
        <div className="dataset-design-rows">
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th></th>
                        <th>Template</th>
                        <th>Design</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            <td className="cell-index">
                                <div>{row['rowIndex'] + 1}</div>
                                {userRoles.indexOf('admin') > -1 && (
                                    <div>
                                        <a href={Constant.STUDIO_API + 'admin/free_resources/datasetdesignrow/' + row['id']} target="_blank">Row ID: {row['id']}</a>
                                    </div>
                                )}
                            </td>
                            <td>
                                <DataSetDesignRowTemplatePreview templateID={row['template']} />
                            </td>
                            <td>
                                <DataSetDesignRowPreview
                                    dataSetID={dataSet.id}
                                    designRow={row}
                                    updateRow={data => {
                                        setRows(rows.map(r => {
                                            if (r.id === data.id) {
                                                return data;
                                            }
                                            return r;
                                        }))
                                    }} />
                            </td>
                            <td>
                                <div className="btns">
                                    <Button size="sm" variant="danger" onClick={() => deleteRow(row['id'])}>Delete</Button>
                                    <Button size="sm" variant="info" onClick={() => duplicateRow(row['id'])}>Duplicate</Button>
                                    <Button size="sm" variant="success" onClick={() => refreshRow(row['id'])}>Refresh</Button>
                                </div>
                                <div className="btns">
                                    <Button size="sm" variant="secondary" onClick={() => moveRow(row.id, 'up')}>
                                        <FontAwesomeIcon icon={faArrowUp} />
                                    </Button>
                                    <Button size="sm" variant="secondary" onClick={() => moveRow(row.id, 'down')}>
                                        <FontAwesomeIcon icon={faArrowDown} />
                                    </Button>
                                    <Dropdown as={ButtonGroup} size="sm">
                                        <Button variant={row?.content?.audio ? 'info' : 'secondary'} size="sm" onClick={() => {
                                            if (row?.content?.audio) {
                                                playAudio(row.content.audio);
                                            }
                                        }}>
                                            <FontAwesomeIcon icon={faVoicemail} />
                                        </Button>

                                        <Dropdown.Toggle split id="audio-mail-dropdown" variant="secondary" />

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => genAudio(row.id)}>Gen Audio</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Button size="sm" onClick={() => setShowNewDesignRowTemplateModal(true)}>Add New Row</Button>

            {showNewDesignRowTemplateModal && dataSet.dataType === 'DESIGN' && (
                <DataSetDesignRowSelectTemplateModal
                    insertDesignRows={insertDesignRows}
                    hideModal={() => setShowNewDesignRowTemplateModal(false)} />
            )}
        </div>
    )
}

export default DataSetDesignRows;