import Select from 'react-select';
import { useSelector } from 'react-redux';

const FilterByStores = ({ filteringStores, setFilteringStores }) => {
    const stores = useSelector(state => state.filters.stores);
    const { userRoles } = useSelector((state) => state.permissions);

    const handleChange = selectedOptions => {
        setFilteringStores(selectedOptions ? selectedOptions.map(option => option.value) : []);
    };

    const options = stores.map(store => ({
        value: store.id,
        label: store.name
    }));

    return (
        <Select
            isMulti
            value={options.filter(option => filteringStores.includes(option.value))}
            onChange={handleChange}
            options={options}
            isDisabled={!userRoles.includes('admin')}
        />
    );
};

export default FilterByStores;