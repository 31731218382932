import { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import DesignTicketModal from '../../design-ticket/design-ticket-modal';

const HotKeyController = ({ pages, showPagesControlModal, showOutlineModal }) => {
    const [pageNumber, setPageNumber] = useState('');
    const [showGoToPageModal, setShowGoToPageModal] = useState(false);
    const [showNewDesignTicketModal, setShowNewDesignTicketModal] = useState(false);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey || event.metaKey) {
                console.log(event.key);
                if (event.key === 'p') {
                    event.preventDefault();
                    showPagesControlModal();
                } else if (event.key === 'g') {
                    event.preventDefault();
                    setShowGoToPageModal(true);
                } else if (event.key === 'o') {
                    event.preventDefault();
                    showOutlineModal();
                } else if (event.key === 'd') {
                    event.preventDefault();
                    setShowNewDesignTicketModal(true);
                    console.log('toto');
                }
            }
        };
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleGoToPage = () => {
        let pageIndex = parseInt(pageNumber);

        if (!isNaN(pageIndex)) {
            const page = pages.find((page, index) => index === pageIndex - 1);

            if (page) {
                const pageElement = document.getElementById(`row-${page.id}`);

                if (pageElement) {
                    pageElement.scrollIntoView({ behavior: "smooth" });
                }
            }
        }
        setPageNumber('');
        setShowGoToPageModal(false);
    };

    return (
        <>
            {showGoToPageModal && (
                <Modal show={true} onHide={() => setShowGoToPageModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Go To Page</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control
                            type="number"
                            value={pageNumber}
                            style={{ width: '100%' }}
                            onChange={(e) => setPageNumber(e.target.value)}
                            autoFocus />
                    </Modal.Body>
                    <Modal.Footer>
                        {(pageNumber >= 1 && pageNumber <= pages.length) && (
                            <Button variant="primary" onClick={handleGoToPage}>
                                Go to page
                            </Button>
                        )}

                    </Modal.Footer>
                </Modal>
            )}
            {showNewDesignTicketModal && (
                <DesignTicketModal
                    hideModal={() => setShowNewDesignTicketModal(false)} />
            )}
        </>
    );
}

export default HotKeyController;