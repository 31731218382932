import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import { toast } from 'react-toastify';
import './styles.scss';
import DataSetRow from '../../components/dataset/data-set-row';
import { Spinner } from 'react-bootstrap';

const DataSetPage = () => {
    const [selectedDataSet, setSelectedDataSet] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dataSet, setDataSet] = useState(null);

    useEffect(() => {
        const id = (new URLSearchParams(window.location.search)).get('id');
        if (id) {
            fetchDataSet(id);
        }
    }, []);

    const fetchDataSet = async (id) => {
        const res = await getDataFromAPI('resources/data-sets/' + id + '/');
        setDataSet(res.data);
    }

    const handleDeleteDataSet = async (dataSetID) => {
        const confirmed = window.confirm('Are you sure want to delete this data set?');
        if (confirmed) {
            try {
                await updateDataAPI('DELETE', `resources/data-sets/${dataSetID}/`);
                window.location.href = '/data-sets'
                toast.success('Deleted!');
            } catch (err) {
                console.error(`An error occured when deleting data set: ${err.message}`);
            };
        }
    }

    const handleDuplicateDataSet = async (dataSetID) => {
        const store = window.prompt('What is the store of new dataset?', '');
        if (store !== null && !isNaN(store)) {
            setLoading(true);
            const res = await updateDataAPI('POST', `resources/duplicate-data-set/?id=${dataSetID}&storeID=${store}`);
            setLoading(false);
            window.open('/data-set/?id=' + res.data['id'], '_blank', 'noopener,noreferrer');
        }
    }

    if (dataSet === null) {
        return null;
    }

    return (
        <Container className="data-set-page" fluid>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Info</th>
                        <th>Items</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <DataSetRow
                        dataSet={dataSet}
                        showContent={true}
                        setDataSet={setDataSet}
                        handleDeleteDataSet={handleDeleteDataSet}
                        handleDuplicateDataSet={handleDuplicateDataSet}
                        selectedDataSet={selectedDataSet}
                        setSelectedDataSet={setSelectedDataSet}
                        setLoading={setLoading}
                    />
                </tbody>
            </Table>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    );
};

export default DataSetPage;