import { Button, Col, Form, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';

import './styles.scss';
import ImageWrapper from '../../../../components/common/image-wrapper';

const ThumbnailsPreviewsTab = ({
    customID,
    stores,
    thumbnails,
    freePages,
    thumbnailTitle,
    setThumbnailTitle,
    thumbnailSubTitle,
    setThumbnailSubTitle,
    thumbnailSubTitle2,
    setThumbnailSubTitle2,
    highlightImages,
    setLoading,
    updateIdea,
}) => {

    const [freePageImages, setFreePageImages] = useState([]);

    useEffect(() => {
        if (freePages) {
            fetchFreePageImages();
        }
    }, [freePages]);

    const fetchFreePageImages = async () => {
        const res = await getDataFromAPI('resources/idea-pages/?isFreePage=true&customID=' + customID);
        setFreePageImages(res.data.filter(page => page.content && page.content.imageUrl).map(page => page.content.imageUrl));
    }

    const autoAssignThumbnailTemplate = async (storeID) => {
        setLoading(true);
        await updateIdea();
        await updateDataAPI('POST', 'resources/auto-assign-thumbnail-template/?customID=' + customID + '&storeID=' + storeID);
        setLoading(false);
    }

    return (
        <div className="thumbnails-previews-tab">
            <Row className="mt-3">
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Thumbnail Title</Form.Label>
                        <Form.Control value={thumbnailTitle} onChange={e => setThumbnailTitle(e.target.value.toUpperCase())} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Thumbnail SubTitle</Form.Label>
                        <Form.Control value={thumbnailSubTitle} onChange={e => setThumbnailSubTitle(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Thumbnail SubTitle 2 (only in PAID product)</Form.Label>
                        <Form.Control value={thumbnailSubTitle2} onChange={e => setThumbnailSubTitle2(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col md={8}>
                    {stores && thumbnails && (
                        <div className="thumbnails-by-store">
                            {stores.map(store => (
                                <div key={store}>
                                    <div>
                                        <h3>Store {store}</h3>
                                        <Button size="sm" onClick={() => autoAssignThumbnailTemplate(store)}>Assign</Button>
                                    </div>
                                    {thumbnails.filter(thumbnail => thumbnail.store === store).map((thumbnail, index) => (
                                        <div className="thumbnails">
                                            <div>
                                                <ImageWrapper src={thumbnail.imageUrl} />
                                                <h4>{thumbnail.type}</h4>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    )}
                </Col>
            </Row>
            <Row className="mb-3">
                <h3>Hightlight Images</h3>
                {highlightImages && (
                    <div className="highlight-images">
                        {highlightImages.map((image, index) => (
                            <img src={image} key={index} alt="highlight" />
                        ))}
                    </div>
                )}
            </Row>
            <Row>
                <h3>Free Pages</h3>
                <Form.Group className="mb-3">
                    <div className="free-images">
                        {freePageImages.map((image, index) => (
                            <div>
                                <img key={index} src={image} alt="free image page" />
                                {freePages && freePages.split(',').length > 0 && freePages.split(',')[index] && (
                                    <div><strong>Page {freePages.split(',')[index]}</strong></div>
                                )}
                            </div>
                        ))}
                    </div>
                </Form.Group>
            </Row>
        </div>
    )
}

export default ThumbnailsPreviewsTab;