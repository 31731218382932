import { Badge, Button, Form, Table } from 'react-bootstrap';
import ImageWrapper from '../../../components/common/image-wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faRobot, faStar, faTools } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';
import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import { getIdeaToShowInModal } from '../../../utils/commonUtil';
import IdeaModal from '../../idea-list/idea-modal';
import Constant from '../../../Constant';

import './styles.scss';
import UploadProgressChartModal from '../upload-progress-chart-modal';

const StarIdeasTab = ({ filteringStore, filteringTheme, setLoading }) => {
    const [starIdeas, setStarIdeas] = useState([]);
    const [ideaInModal, setIdeaInModal] = useState(null);
    const [showUploadProgressChartModal, setShowUploadProgressChartModal] = useState(false);
    const [nbProductsUploadedToday, setNbProductsUploadedToday] = useState(0);

    useEffect(() => {
        fetchStarIdeas();
    }, [filteringStore]);

    const fetchStarIdeas = async () => {
        setLoading(true);
        const resStarIdeas = await getDataFromAPI('resources/get-boom-star-ideas-to-upload/?storeID=' + filteringStore);
        const starIdeas = resStarIdeas.data.sort((a, b) => {
            if (a.product.boomUploadTime && !b.product.boomUploadTime) {
                return 1;
            }
            if (!a.product.boomUploadTime && b.product.boomUploadTime) {
                return -1;
            }

            return 0;
        });
        setStarIdeas(starIdeas);

        const resStore = await getDataFromAPI('stores/' + filteringStore + '/');
        setNbProductsUploadedToday(resStore.data['boomNbProductsUploadedToday']);
        setLoading(false);
    }

    const autoAssignStarIdeas = async () => {
        setLoading(true);
        await updateDataAPI('POST', 'resources/auto-assign-boom-star-ideas/', {storeID: filteringStore});
        await fetchStarIdeas();
        setLoading(false);
    }

    const showIdeaModal = async (ideaID) => {
        const data = await getIdeaToShowInModal(ideaID);
        setIdeaInModal(data);
    }

    const resetDesignTicket = async (ideaID, designTicketID) => {
        setLoading(true);
        await updateDataAPI('PATCH', 'product-dev/design-tickets/' + designTicketID + '/', { nbFails: 0 });
        setStarIdeas(starIdeas.map(idea => {
            if (idea.id === ideaID) {
                return { ...idea, designTicketNbFails: 0 };
            }
            return idea;
        }));
        setLoading(false);
    }

    const createUploadTicket = async (ideaID) => {
        setLoading(true);
        const res = await updateDataAPI('POST', 'product-dev/upload-tickets/', {
            idea: ideaID,
            actionType: 'UPLOAD_NEW_PRODUCT',
            toUploadProduct: ['paid']
        });
        setStarIdeas(starIdeas.map(idea => {
            if (idea.id === ideaID) {
                return {
                    ...idea, boomUploadTicket: {
                        id: res.data['id'],
                        hasError: res.data['hasError'],
                        failedReason: res.data['failedReason'],
                    }
                };
            }
            return idea;
        }));
        setLoading(false);
    }

    const clearBoomValidateErrors = async (ideaID, productID, boomNbManualClearErrors) => {
        setLoading(true);
        await updateDataAPI('PATCH', 'products/' + productID + '/', {
            boomValidateErrors: null,
            boomNbManualClearErrors: boomNbManualClearErrors + 1,
        });
        setStarIdeas(starIdeas.map(idea => {
            if (idea.id === ideaID) {
                return {
                    ...idea,
                    product: {
                        ...idea.product,
                        boomValidateErrors: null,
                        boomNbManualClearErrors: boomNbManualClearErrors + 1
                    }
                };
            }
            return idea;
        }));
        setLoading(false);
    }

    const clearBoomUploadTicketErrors = async (ideaID, ticketID) => {
        setLoading(true);
        await updateDataAPI('PATCH', 'product-dev/upload-tickets/' + ticketID + '/', {
            hasError: false,
            failedReason: null
        });
        setStarIdeas(starIdeas.map(idea => {
            if (idea.id === ideaID) {
                return {
                    ...idea, boomUploadTicket: {
                        ...idea.boomUploadTicket,
                        hasError: false,
                        failedReason: null
                    }
                };
            }
            return idea;
        }));
        setLoading(false);
    }

    const getFilteredIdeas = () => {
        return starIdeas.filter(idea => {
            if (filteringStore !== 'all' && idea.store != filteringStore) {
                return false;
            }

            if (filteringTheme !== 'all' && idea.tags.indexOf(filteringTheme) === -1) {
                return false;
            }

            return true;
        });
    }

    const publishProduct = async (ideaID, productID) => {
        setLoading(true);
        const res = await updateDataAPI('PATCH', 'products/' + productID + '/', {
            boomPublished: true,
            boomPublishTime: new Date(),
            boomUploadValidated: true,
            boomValidateErrors: null,
        });
        setStarIdeas(starIdeas.map(idea => {
            if (idea.id === ideaID) {
                return {
                    ...idea,
                    product: {
                        ...idea.product,
                        boomPublishTime: res.data['boomPublishTime'],
                        boomPublished: true,
                        boomValidateErrors: null,
                    }
                };
            }
            return idea;
        }));
        setLoading(false);
    }

    const autoAssignThumbnail = async (ideaID, customID) => {
        setLoading(true);
        const res = await updateDataAPI('POST', 'resources/auto-assign-thumbnail-template/?customID=' + customID + '&storeID=' + filteringStore);
        if (res.data.find(thumbnail => thumbnail.type === 'paid')) {
            setStarIdeas(starIdeas.map(idea => {
                if (idea.id === ideaID) {
                    return {
                        ...idea,
                        product: {
                            ...idea.product,
                            ideaThumbnail: {
                                id: res.data.find(thumbnail => thumbnail.type === 'paid').id,
                                imageUrl: null,
                            }
                        }
                    };
                }
                return idea;
            }));
        }
        setLoading(false);
    }

    const refreshIdea = async (ideaID, taskID) => {
        setLoading(true);
        await updateDataAPI('POST', 'product-dev/force-update-task-progress/?taskID=' + taskID);
        const res = await getDataFromAPI('product-ideas/' + ideaID + '/?store=' + filteringStore + '&additionalFields=products,tasks,thumbnails');
        setStarIdeas(starIdeas.map(idea => {
            if (idea.id === ideaID) {
                return {
                    ...idea,
                    product: {
                        ...idea.product,
                        task: {...idea.product.task, stepPos: res.data['tasks'][0].stepPos, stepLabel: res.data['tasks'][0].stepLabel}
                    },
                    ideaThumbnail: {
                        id: res.data['thumbnails'][0].id,
                        imageUrl: res.data['thumbnails'][0].imageUrl,
                    }
                };
            }
            return idea;
        }));
        setLoading(false);
    }

    const recoverIdeaCoords = async(customID) => {
        if (window.confirm('Are you sure you want to recover idea coords?')) {
            await updateDataAPI('POST', 'auto-content/recover-idea-coords/?customID=' + customID);
        }
    }

    return (
        <div className="star-ideas-tab">
            <div className="btns">
                {filteringStore !== 'all' && (
                    <Button size="sm" onClick={autoAssignStarIdeas}>Auto Assign Ideas Today</Button>
                )}
                <Button size="sm" onClick={fetchStarIdeas}>Refresh</Button>
                <Button size="sm" onClick={() => setShowUploadProgressChartModal(true)}>Upload Progress Chart</Button>
            </div>
            <div className="mt-3">{nbProductsUploadedToday} products uploaded today</div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>{getFilteredIdeas().length} ideas</th>
                        <th>Store</th>
                        <th>Title</th>
                        <th>Design Done</th>
                        <th>Upload Done</th>
                        <th>Publish Done</th>
                    </tr>
                </thead>
                <tbody>
                    {getFilteredIdeas().map((idea, index) => (
                        <tr key={idea.customID}>
                            <td>{index + 1}</td>
                            <td>{idea.store}</td>
                            <td>
                                <div className="idea-info-cell">
                                    {idea.product.ideaThumbnail ? (
                                        <div>
                                            {idea.product.ideaThumbnail.imageUrl ? (
                                                <ImageWrapper src={idea.product.ideaThumbnail.imageUrl} />
                                            ) : (
                                                <div>Rendering...</div>
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            <div>
                                                <Badge bg="danger">No Thumbnail</Badge>
                                            </div>
                                            <div>
                                                <Button size="sm" onClick={() => autoAssignThumbnail(idea.id, idea.customID)}>Auto Assign</Button>
                                            </div>
                                        </div>
                                    )}
                                    <div>
                                        <div className="idea-title" onClick={() => showIdeaModal(idea.id)}>{idea.customID}. {idea.title}</div>
                                        <div>
                                            <a href={Constant.API_URL + 'admin/free_resources/product/' + idea.product.id} target="_blank">Product {idea.product.id}</a>
                                        </div>
                                        <div>
                                            <Badge bg={idea.product.task.stepPos < 6 ? 'danger' : 'success'}>{idea.product.task.stepLabel}</Badge>
                                        </div>
                                        <div>Design Validated: {idea.product.designValidatedPages}</div>
                                        <div>
                                            Keyword: {idea.keyword?.name} - grades {idea.keyword?.grades}
                                            {idea.keyword?.star && (
                                                <FontAwesomeIcon icon={faStar} className="text-warning" />
                                            )}
                                        </div>
                                    </div>

                                    <div className="actions">
                                        <FontAwesomeIcon icon={faTools} onClick={() => recoverIdeaCoords(idea.customID)} />
                                        <FontAwesomeIcon icon={faRefresh} onClick={() => refreshIdea(idea.id, idea.product.task.id)} />
                                        <a href={'/content-studio/?customID=' + idea.customID} target="_blank">
                                            <FontAwesomeIcon icon={faRobot} />
                                        </a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                {idea.designDone ? (
                                    <Badge bg="success">Done</Badge>
                                ) : (
                                    <div>
                                        <div>{idea.designTicketPercentage}%</div>
                                        {idea.designTicketNbFails > 0 && (
                                            <div>
                                                <Badge bg={idea.designTicketNbFails > 3 ? 'danger' : 'warning'}>{idea.designTicketNbFails} fails</Badge>
                                                (<span onClick={() => resetDesignTicket(idea.id, idea.designTicket)} className="reset-design-ticket">reset</span>)
                                            </div>
                                        )}
                                    </div>
                                )}
                            </td>
                            <td>
                                {idea.product.boomUploadTime ? (
                                    <div>
                                        <div><Moment format="HH:mm DD/MM/YYYY">{idea.product.boomUploadTime}</Moment></div>
                                        {idea?.product?.url && (
                                            <a href={idea?.product?.url.replace('/deck/', '/editDeck/')}>edit deck</a>
                                        )}
                                    </div>
                                ) : (
                                    <>
                                        {idea.boomUploadTicket && (
                                            <div>
                                                <div>
                                                    <a href={Constant.API_URL + 'admin/product_dev/uploadticket/' + idea.boomUploadTicket.id} target="_blank">Ticket {idea.boomUploadTicket.id}</a>
                                                </div>
                                                {idea.boomUploadTicket.hasError && (
                                                    <div>Error: {idea.boomUploadTicket.failedReason} <span onClick={() => clearBoomUploadTicketErrors(idea.id, idea.boomUploadTicket.id)}>(clear)</span></div>
                                                )}
                                            </div>
                                        )}
                                        {idea.boomUploadTicket === null && idea.designDone && idea.product.boomValidateErrors === null && idea.product.ideaThumbnail && idea.product.ideaThumbnail.imageUrl && (
                                            <Button size="sm" onClick={() => createUploadTicket(idea.id)}>Create Upload Ticket</Button>
                                        )}
                                    </>
                                )}
                            </td>
                            <td>
                                {idea.product.boomPublishTime ? (
                                    <Moment format="HH:mm DD/MM/YYYY">{idea.product.boomPublishTime}</Moment>
                                ) : (
                                    <div>
                                        {idea.product.boomValidateErrors && (
                                            <div>
                                                <Form.Control as="textarea" value={idea.product.boomValidateErrors} rows={10} disabled />
                                                <a href={idea.product.url?.replace('/deck/', '/editDeck/')}>Edit Deck</a>
                                                <Button size="sm" onClick={() => clearBoomValidateErrors(idea.id, idea.product.id, idea.product.boomNbManualClearErrors)}>
                                                    clear ({idea.product.boomNbManualClearErrors} times)
                                                </Button>
                                            </div>
                                        )}
                                        {idea.product.boomUploadTime && (
                                            <Button size="sm" onClick={() => publishProduct(idea.id, idea.product.id)}>Published</Button>
                                        )}
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {showUploadProgressChartModal && (
                <UploadProgressChartModal
                    store={filteringStore}
                    hideModal={() => setShowUploadProgressChartModal(false)}
                />
            )}

            {ideaInModal !== null && (
                <IdeaModal
                    idea={ideaInModal}
                    closeModal={() => {
                        setIdeaInModal(null);
                    }}
                />
            )}
        </div>
    )
}

export default StarIdeasTab;