import { useState } from 'react';
import { updateDataAPI } from '../../../../../utils/query';
import ImageWrapper from '../../../../common/image-wrapper';

import './styles.scss';
import { Button, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faVoicemail } from '@fortawesome/free-solid-svg-icons';
import UploadFile from '../../../../content-studio/upload-file';

const DataSetDesignRowPreview = ({dataSetID, designRow, updateRow}) => {
    const [showContentEditModal, setShowContentEditModal] = useState(false);
    const [contentInModal, setContentInModal] = useState(null);
    const [iconUploading, setIconUploading] = useState(false);

    const save = async() => {
        await updateDataAPI('PATCH', 'resources/data-set-design-rows/' + designRow.id + '/', {...designRow, content: JSON.parse(contentInModal)});
        updateRow({...designRow, content: JSON.parse(contentInModal)});
        setShowContentEditModal(false);
        setContentInModal(null);
    }

    const saveContent = async(content) => {
        await updateDataAPI('PATCH', 'resources/data-set-design-rows/' + designRow.id + '/', {...designRow, content});
        updateRow({...designRow, content});
    }

    const playAudio = async(audioUrl) => {
        const audio = new Audio(audioUrl);
        audio.play();
    }

    return (
        <div className="dataset-design-row-preview">
            {designRow.imageUrl && (
                <ImageWrapper src={designRow.imageUrl} />
            )}
            <div className="btns">
                <Button size="sm" variant={designRow['content'] !== null && Object.keys(designRow['content']).length > 0 ? 'info' : 'secondary'} onClick={() => {
                    setShowContentEditModal(true);
                    setContentInModal(JSON.stringify(designRow['content'], null, 4));
                }}>Edit JSON</Button>
                <Button
                    href={process.env.REACT_APP_WORKSHEET_MAKER_URL + '?type=data_set_design_row&id=' + designRow.id + (designRow['content'] && designRow['content']['tinyTapID'] ? '&tinyTapID=' + designRow['content']['tinyTapID'] : '')}
                    variant="success" size="sm" target="_blank" rel="noreferrer"
                >Design</Button>
                {designRow.content && designRow.content['audio'] && (
                    <Button variant="info" size="sm" onClick={() => {
                        playAudio(designRow.content['audio']);
                    }}>
                        <FontAwesomeIcon icon={faVoicemail} />
                    </Button>
                )}
                <Button size="sm" variant="secondary" onClick={() => document.querySelector('.dataset-design-row-' + designRow.id + '-upload input[type=file]').click()} className="upload-icon-btn">
                    <FontAwesomeIcon icon={faUpload} />
                    <div>
                        <UploadFile
                            id={dataSetID}
                            className={'dataset-design-row-' + designRow.id + '-upload'}
                            setLoading={setIconUploading}
                            uploadUrl={'resources/upload-data-set-icons/'}
                            allowedFileExtentions={['png', 'jpg', 'jpeg']}
                            fieldName={'temp'}
                            uploadedFileName={null}
                            setUploadedFileName={data => {
                                let content;
                                if (designRow['content'] !== null) {
                                    content = {...designRow['content'], 'image': Object.values(data)[0]};
                                } else {
                                    content = {'image': Object.values(data)[0]};
                                }
                                saveContent(content);
                            }}
                            multiple={true}
                        />
                        {iconUploading && (
                            <div>Uploading...</div>
                        )}
                    </div>
                </Button>
            </div>

            {showContentEditModal && (
                <Modal size="lg" show={true} onHide={() => setShowContentEditModal(false)}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <Form.Control as="textarea"
                            value={contentInModal}
                            onChange={e => setContentInModal(e.target.value)}
                            rows={10}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={save}>Save</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    )
}

export default DataSetDesignRowPreview;