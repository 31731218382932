import { useEffect, useState } from 'react';
import { Badge, Button, Card, Container, Form, Spinner } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';

import './styles.scss';
import ActivityTemplateSetInfoModal from '../../components/activity-template-set/activity-template-set-info-modal';
import FilterByTheme from '../../filters/theme';
import FilterByActivity from '../../filters/activity';

const ActivityTemplateSetsPage = () => {
    const [filteringActivity, setFilteringActivity] = useState('all');
    const [filteringTheme, setFilteringTheme] = useState('all');
    const [filteringStoreGroup, setFilteringStoreGroup] = useState('all');

    const [templateSets, setTemplateSets] = useState([]);
    const [templateSetIDInModal, setTemplateSetIDInModal] = useState(null);
    const [showTemplateSetModal, setShowTemplateSetModal] = useState(false);
    const [storeGroups, setStoreGroups] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchTemplateSets();
        fetchTemplateSetStoreGroups();
    }, []);

    const fetchTemplateSets = async() => {
        setLoading(true);
        const res = await getDataFromAPI('resources/activity-template-sets/');
        setTemplateSets(res.data);
        setLoading(false);
    }

    const fetchTemplateSetStoreGroups = async() => {
        setLoading(true);
        const res = await getDataFromAPI('resources/store-groups/');
        setStoreGroups(res.data);
        setLoading(false);
    }

    const autoGenerateTemplateSets = async() => {
        setLoading(true);
        await updateDataAPI('POST', 'resources/auto-generate-template-sets/');
        fetchTemplateSets();
        setLoading(false);
    }

    const getTotalTemplatesRequirements = (set) => {
        let nbFilledRequirements, nbNeededRequirements;
        if (set.activityData.slug === 'boom_mystery' || set.activityData.slug === 'boom_mystery_unscramble' || set.activityData.slug === 'boom_build_something') {
            if (set.storeGroup === null) {
                nbFilledRequirements = Object.keys(set.templates).filter(key => !key.startsWith('image') && set.templates[key] !== null).length;
                nbNeededRequirements = set.activityData.templateRequirements.filter(req => !req['slug'].startsWith('image')).length;
            } else {
                nbFilledRequirements = Object.keys(set.templates).filter(key => key.startsWith('image') && set.templates[key] !== null).length;
                nbNeededRequirements = set.activityData.templateRequirements.filter(req => req['slug'].startsWith('image')).length;
            }
        } else {
            nbFilledRequirements = Object.values(set.templates).filter(val => val !== null).length;
            nbNeededRequirements = set.activityData.templateRequirements.length;
        }

        if (nbFilledRequirements > nbNeededRequirements) {
            nbFilledRequirements = nbNeededRequirements;
        }
        return nbFilledRequirements + '/' + nbNeededRequirements;
    }

    return (
        <Container className="activity-template-sets-page">
            <div className="page-header">
                <div className="filters">
                    <FilterByActivity
                        filteringActivity={filteringActivity}
                        setFilteringActivity={setFilteringActivity}
                        storeGroup={filteringStoreGroup !== 'all' ? storeGroups.find(g => g.id == filteringStoreGroup) : null}
                    />
                    <div className="theme-filter">
                        <FilterByTheme filteringTheme={filteringTheme} setFilteringTheme={setFilteringTheme} />
                    </div>
                    <Form.Select value={filteringStoreGroup} onChange={e => setFilteringStoreGroup(e.target.value)}>
                        <option value="all">Common</option>
                        {storeGroups.map(group => (
                            <option key={group['id']} value={group['id']}>Group {group['id']} ({group['label']})</option>
                        ))}
                    </Form.Select>
                </div>
                <div>
                    <Button onClick={() => setShowTemplateSetModal(true)}>Add New Set</Button>
                    {/* <Button size="sm" onClick={autoGenerateTemplateSets}>Auto Generate</Button> */}
                </div>
            </div>
            <div className="template-sets">
                {templateSets.filter(set => {
                    if (filteringActivity !== 'all' && set.activity != filteringActivity) {
                        return false;
                    }

                    if (filteringTheme !== 'all' && set.theme !== filteringTheme) {
                        return false;
                    }

                    if (filteringStoreGroup !== 'all') {
                        if (set.storeGroup != filteringStoreGroup) {
                            return false;
                        }

                        const storeGroup = storeGroups.find(g => g.id === set.storeGroup);
                        if (storeGroup['activities'].indexOf(set.activityData['slug']) === -1) {
                            return false;
                        }
                    } else if (set.storeGroup !== null) {
                        return false;
                    }

                    return true;
                }).map(set => (
                    <Card key={set.id} onClick={() => {
                        setTemplateSetIDInModal(set.id);
                        setShowTemplateSetModal(true);
                    }} bg={set.active ? (set.validated ? 'success' : (set.templates === null ? 'danger' : 'warning')) : 'secondary'}>
                        <Card.Body>
                            <Card.Title>Set {set.id}. {set.activityData.label}</Card.Title>
                            <Card.Text>Theme: {set.theme ? set.themeData.name : 'Common'}</Card.Text>
                            <div className="badges">
                                {!set.validated && set.contentValidated && (
                                    <Badge bg="success">Content Validated</Badge>
                                )}
                                {set.templates && (
                                    <Badge bg="secondary">
                                        {getTotalTemplatesRequirements(set)}
                                    </Badge>
                                )}
                                {set.supportLongQuestion && (
                                    <Badge bg="secondary">
                                        Long Question
                                    </Badge>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                ))}
            </div>

            {showTemplateSetModal && (
                <ActivityTemplateSetInfoModal
                    templateSetID={templateSetIDInModal}
                    updateTemplateSet={data => {
                        setTemplateSets(templateSets.map(set => {
                            if (set.id === templateSetIDInModal) {
                                return data;
                            }
                            return set;
                        }));
                    }}
                    createTemplateSet={data => {
                        setTemplateSets([...templateSets, data]);
                    }}
                    deleteTemplateSet={() => {
                        setTemplateSets(templateSets.filter(set => set.id !== templateSetIDInModal));
                    }}
                    hideModal={() => {
                        setShowTemplateSetModal(false);
                        setTemplateSetIDInModal(null);
                    }}
                />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default ActivityTemplateSetsPage;